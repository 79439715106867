import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

// import axios from 'axios';

import { DOMAIN } from '../../APIClient/endPoints';
import classes from "./ServiceCard.module.css";

const ServiceCard = props => {
    const { t } = useTranslation()

    useEffect(() => {
        props.changed()
    })

    const ServiceHandler = () => {
        props.selectItems(props.item.id)
    }

    return <div  className={props.disabled ? classes[props.class] : `${classes[props.class]} ${classes.disabled}` }
        id={props.item.id}
        onClick={ServiceHandler} 
    >
        <img className={classes.logo} src={`${DOMAIN}${props.item.image}`}  alt='logo'/>
        <span className={classes['card-logo-text']}>{props.item.name}</span>
        {!props.disabled? <span>{`(${t('GLOBAL.SOON')})`}</span>: null}
    </div>
}

export default ServiceCard