import { HOST } from "../APIClient/endPoints";

export function validateUserInfo(data, valid){
    let validation = Object.entries(data).filter(item => item[1].trim().length === 0)

    if (validation.length > 0) {
        valid(validation)
        return false 
    }
    return true
} 

export function formatServices(inputs, others) {

    const keys = Object.keys(inputs)
    const {activeServices, webServices, webAdmin, aditionals, ...items} = inputs
    let services = []
    let data = [] 
    let extras = []
    activeServices.map(service => {
        if(service.selected) services.push({service: service.name}) 
        return service
    })
    keys.forEach(key => { data = data.concat(items[key])})
    data = data.filter(item => item && item?.amount !== 0).map(item => {
        if (item.social==='Presupuesto de pauta publicitaria mensual' || item.social==='Advertising budget'){
            extras = extras.concat([{
                    name: 'Social Media',
                    price: item.price, 
                    content: item.social 
                }])
            return false;
        }
        if (item.data) {
            extras = extras.concat(item.data.map(d => {
                return {
                    name: 'Desarrollo Web',
                    price: item.price, 
                    relates_to: item.id,
                    content: d.value,
                }
            }))
            item.amount = item.data.length
            return false;
        }
        if (item?.id){
            return {
                item: item.id,
                name: item.social,
                amount: item.amount ? item.amount : 1
            }
        }
    }).filter(d => d!==false && d)
    activeServices.forEach(service => {
        service?.items.map(item => {
            if (item.selected) data.push({item: item.id, amount: 1})
            return 0;
        })
    })
    if (Object.keys(webServices).length > 0) data.push({item: webServices.webTypeSelected.id, amount: 1}, {item: webServices.template.id, amount: 1})
    if (Object.keys(webAdmin).length > 0) data.push({item: webAdmin.id, amount: 1})
    if (aditionals.length > 0){
        aditionals.map(aditional => {
            extras.push({name: aditional.name, content: aditional.content, price: 0})
            return true
        })
    }
    const client= {
        ...others.userInformation,
        email: others.email,
        phone: localStorage.getItem("phone")
    }
    localStorage.removeItem("phone")

    return {
        link: `${HOST}quote-summary/`,
        services,
        items: data,
        extras:extras,
        client,
        language: others.language,
        currency: others.currency,
        deduction: others.userInformation.deduction ? Number(others.userInformation.deduction):0,
        code: others.userInformation.deductionCode ? others.userInformation.deductionCode: 'na',
        total: others.totalFormat
    }
}

