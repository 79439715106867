import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import {
  servicesReducer,
  socialReducer,
  aditionalServices,
} from "./redux/servicesRedux";
import { indexReducer } from "./redux/indexRedux";
import { currentReducer, email} from "./redux/currency";
import { webReducer, webItemsReducer, webAdminReducer } from "./redux/webRedux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

import "./i18n";

const persistConfig = {
  key: "persist-key",
  storage,
};

const reducers = combineReducers({
  services: servicesReducer,
  index: indexReducer,
  social: socialReducer,
  currency: currentReducer,
  email: email,
  // numPost: numPost,
  web: webReducer,
  webItems: webItemsReducer,
  webAdmin: webAdminReducer,
  aditional: aditionalServices
});
const persistedReducer = persistReducer(persistConfig, reducers);
const stored = createStore(persistedReducer);
const persistor = persistStore(stored);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<span>Loading...</span>}>
      <Provider store={stored}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
