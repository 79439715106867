import { useEffect, useState } from 'react'
import useStorage from '../../../hooks/useStorage'
import { useTranslation } from 'react-i18next'; 

import RadioYesNo from "../../Common/RadioYesNo/RadioYesNo"
import classes from './InformativeWeb.module.css'

import { fetchAPIItemRelatesTo } from "../../../APIClient/fetchAPI"
 
const InformativeWeb = () => {
    /**Se tiene que el usuario sí o si debe seleccionar una de las card en la pagina anterior. Esta card activa se guarda en el storage
     * por lo que podemos obtenerla aquí y apartir de ella mostras el formulario completo o solo una parte. Esta funcionalidad se hará 
     * cuando ya tengamos los datos.
     */
    const { webServices } = useStorage()
    const [items, setItems] = useState([])
    const { i18n } = useTranslation()
    let render;
    useEffect(async () => {
        await fetchAPIItemRelatesTo({service: 'Desarrollo Web', item_type: 'group' }, i18n.language).then(response => setItems(response.data.data))
    }, [])

    if (webServices.webTypeSelected?.name === 'Tienda online (e-commerce)' ||  webServices.webTypeSelected?.name === 'E-commerce'){
        render = items
    }else render = items.filter(item => item.name !== 'Pasarela de pagos' && item.name !== 'Envío de los productos')

    if (webServices.template?.name.includes("Shopify")){
        render = render.filter(item => item.name !== 'Login')
        render = render.map(item => {
            if (item.name.includes('Extra')) {
                let sub_items = item.sub_items
                sub_items = sub_items.filter(item => item.name !== 'Bucador interno' && item.name !== 'Internal seeker' && item.name !== 'Advertising sections' && item.name !== 'Secciones publicitarias')
                item.sub_items = sub_items
                return item
            }
            return item
        })
    }

    return <div className={classes['informative-web-container']}>
        {render.map((item) => <RadioYesNo key={item.id} item={item} />)}
    </div>
}

export default InformativeWeb