import AditionalServicesCard from "./AditionalServicesCard";
import CustomMarketing from "./customMarketing/CustomMarketing";
import classes from "./AditionalServices.module.css";

const AditionalServices = (props) => {
  const aditionalServices = props.aditional.map((item) => {
    return {
      ...item,
      amount: 1
    }
  })
  return (
    <div className={classes.container}>
      {aditionalServices.map((aditional) => {
        if (aditional.name === "Presupuesto de pauta publicitaria mensual" || aditional.name === "Advertising budget"){
          return <CustomMarketing key={aditional.id} current={aditional} active = {props.active.find(active => active.id === aditional.id)}/>
        }else{
          return <AditionalServicesCard
          key={aditional.id}
          current={aditional}
          active = {props.active.find(active => active.id === aditional.id)}
        />
        }
      })}
    </div>
  );
};

export default AditionalServices;
