import { useState } from 'react'
import AdminWebItem from '../AdminWebItem/AdminWebItem'

import classes from './AdminWebGroupItems.module.css'

const AdminWebGroupItems = ({items}) => {
    const [active, setActive] = useState(null)

    return <div className={classes['admin-group-container']} >
        {items.map((item) => <AdminWebItem key={item.id} item={item} onActive={setActive} active={active === item.id} />)}
    </div> 
}

export default AdminWebGroupItems