import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import ServiceCard from './ServiceCard'
import ButtonsNextBack from '../Common/ButtonsNextBack'
// import Footer from '../Common/Footer'
import BACKGROUND from "../../assests/images/Fondo.png"
import classes from "./Services.module.css"


// const selectItems = []; //Se almacenan los ids de los servicios almacenados

const Services = (props) => {
    const [activeBtn, setActiveBtn] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const services = useSelector(state => state.services)[0]
    const { t } = useTranslation()

    const clickServiceHandler = (id) => {
        dispatch({
            type: '@service/selected',
            action: {
                id: id
            }
        })
    }
    const AvailableNextBtn = () => {
        const select = services.findIndex(service => service.selected === true)
        if (select === -1) {
            setActiveBtn(false)
        } else {
            setActiveBtn(true)
        }
    }

    function btnBackHandler(e) {
        e.preventDefault();
        navigate("../");
    }

    const btnNextHandler = (e) => {
        e.preventDefault()
        navigate("/custom-services")
    }
    return <div className={classes.container}>
            <div className={classes.background}>
                {/* <img src={BACKGROUND} alt="fondo.png"/> */}
            </div>
        <div className={classes.content} >
            <h2 className={classes.header}>{t('SERVICES.SERVICES')}</h2>
            <div className={classes.services} >
                {services.map((item, idx) => <ServiceCard
                    key={item.id}
                    item={item}
                    selectItems={clickServiceHandler}
                    changed={AvailableNextBtn}
                    class={item.selected ? 'service-card-active' : 'service-card'}
                    disabled={item.active}
                />)}
            </div>
            <ButtonsNextBack className={classes.btns} onBtnBack={btnBackHandler} onBtnNext={btnNextHandler} disabled={activeBtn} />
        </div>
    </div>
}

export default Services