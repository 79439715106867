import { BrowserRouter, Routes, Route } from 'react-router-dom'

import NotFound from './components/Common/NotFound'
import Navigation from './components/Common/Navigation';
import Footer from './components/Common/Footer'
import WelcomePage from './components/WelcomePage/WelcomePage';
import Services from './components/ServicesPage/Services'
import ServicesDetails from './components/ServicesDetails/ServicesDetails'
import ItemsService from './components/ServicesDetails/ItemsService'
import ClientPage from './components/ClientsInfo/clientPage'

import LandingPage from './components/LandingPage/LandingPage'
import Prueba from './components/ClientsInfo/Policy'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Logo from "./assests/logo/logowikpis.png"


function App() {

  
  
  return (
      <BrowserRouter>
        <Navigation />
        <div style={{width: "100%", height: "60px"}}></div>
        <Routes>
          <Route path="/" element={ <WelcomePage /> }/>
          <Route path="/services" element={ <Services /> }/>
          <Route path="/custom-services" element={ <ServicesDetails /> }/>
          <Route path="/items-services" element={ <ItemsService /> }/>
          <Route path="/summary" element={ <ClientPage /> }/>
          <Route path="/quote-summary" element={ <LandingPage/> }/>
          <Route path="/pruebas" element={ <Prueba text='+16000'/> }/>
          <Route path="/wikpis-policy" element={ <Prueba text='+16000'/> }/>
          <Route path="*" element = { <NotFound /> } />
        </Routes>
        <FloatingWhatsApp 
          phoneNumber="+573018093127"
          accountName="Wikpis"
          avatar={Logo}
          chatMessage="¡Hola!"
        />
        <Footer />
      </BrowserRouter>
  );
}

export default App; 