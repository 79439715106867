export const webReducer = (web = {}, action) => {
	switch (action.type) {
		case "@web/init":
			return {};
		case "@web/add":
			return action.payload;
		default:
			return web;
	}
};

export const webItemsReducer = (webItems = [], action) => {
	switch (action.type) {
		case "@webItems/init":
			return [];
		case "@webItems/add-template":
			const data = webItems.filter(webItem => webItem.item_group !== 'none')
			const template = webItems.findIndex(webItem => webItem.id === action.payload.id)
			if (template  === -1) {
				return [...data, action.payload]
			}
			return data

		case "@webItems/add":
			if (webItems.length === 0) {
				return [action.payload];
			} else {
				let item = webItems.findIndex(webItem => webItem.id === action.payload.id)
				if (item  === -1) {
					return [...webItems, action.payload];
				}else {
					return webItems.filter(webItem => webItem.id !== action.payload.id)
				}
			}
		case "@webItems/update":
			if (webItems.length === 0) {
				return [action.payload];
			} else {
				
				let item = webItems.findIndex(webItem => webItem.id === action.payload.id)
				if (item  === -1) {
					return [...webItems, action.payload];
				}
				return webItems.map(webItem => {
					if (webItem.id === action.payload.id){
					  return action.payload
					}
					return webItem
				  })
			}
		case "@webItems/extras":
			let item = webItems.find(webItem => webItem.id === action.payload.id)
		
			if (!item) {
				const data = {...action.payload, ...{data: [action.input]}}
				return [...webItems, data];
			}

			const backup = webItems.filter(webItem => webItem.id !== action.payload.id)
			const idx = item.data.findIndex(data => data.id === action.input.id)
			if (action.remove){
				item.data = item.data.filter(data => data.id !== action.input.id)
			}else{
				if (idx !==-1) item.data[idx] = action.input
				else item.data = [...item.data, action.input]
			}
			return [...backup, item]

		case "@webItems/remove":
			if (action.payload.id) return webItems.filter((webItem) => webItem.id !== action.payload.id);
			else return webItems.filter((webItem) => webItem.relates_to !== action.payload.relates_to);
		default:
			return webItems;
	}
};

export const webAdminReducer = (webAdmin = {}, action) => {
	switch (action.type) {
		case "@webAdmin/init":
			return {};
		case "@webAdmin/add":
			return action.payload;
		default:
			return webAdmin;
	}
}