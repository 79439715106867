import classes from "./Input.module.css";

const InputRadio = ({type, checked, value, onChange=null, styles}) => {
    const checkRadio = (e) => {
        if (onChange) onChange(e.target.value)
    }
    
    return <div className={classes["input-radio-container"]} style={styles}>
        <input type={type} value={value} checked={checked} onChange={checkRadio}/>
    </div>
}

export default InputRadio;