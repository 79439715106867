import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PhoneInput from 'react-phone-number-input'
import { formatPhoneNumberIntl } from 'react-phone-number-input'


import axios from "axios";
import { useTranslation } from "react-i18next";
// import Typewriter from "typewriter-effect"; // libreria con efecto de escritura

import TextInput from "../Common/Inputs/TextInput";
import SelectInput from "../Common/Inputs/SelectInput";
import Button from "../Common/Inputs/Button";
// import Footer from "../Common/Footer";
import CustomModal from "../CustomModal/CustomModal";

import {
  fetchAPIClientsAPIPost,
  fetchAPIServicesAPIGet,
} from "../../APIClient/fetchAPI";

import 'react-phone-number-input/style.css'
import classes from "./WelcomePage.module.css";

const WelcomePage = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("")
  const changePhoneHandler = (phone) => {
    setPhone(phone);
    // props.setUserInformation(
    //     {...props.userInformation, ...{phone: phone}}
    // )
  };
  const [showModal, setShowModal] = useState(null);
  const [activeBtn, setActiveBtn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const optionValues = [{ value: "USD", label: "USD" }];
  if (window.localStorage.getItem("country") === "Colombia") {
    optionValues.push({ value: "COP", label: "COP" });
  }

  const getServices = () => {
    fetchAPIServicesAPIGet(`services/?lang=${i18n.language}`).then(
      (services) => {
        dispatch({ type: "@service/init", services: services.data.results });
      },
      dispatch({ type: "@social/init" }),
      dispatch({ type: "@web/init" }),
      dispatch({ type: "@webItems/init" }),
      dispatch({ type: "@webAdmin/init" }),
      dispatch({ type: "@aditional/init" })
    );
  };
  const getCoin = (currency) => {
    if (currency === "USD") {
      axios
        .get(
          `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/cop.json`
        )
        .then((res) => {
          dispatch({
            type: "@currency/add",
            payload: {
              currency: res.data["cop"]["usd"],
            },
          });
        });
    } else {
      dispatch({
        type: "@currency/add",
        payload: {
          currency: 1,
        },
      });
    }
  };

  useEffect(() => {
    getServices();
    getCoin(i18n.language === "en" ? "USD" : "COP");
  }, []);

  const submitHandler = async (event) => {
    event.preventDefault();
    var regex = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (email.trim().length === 0 || regex.test(email) === false) {
      setShowModal({
        message:
          "¡El correo inválido! El correo no cumple con el formato establecido",
      });
    }
    let fullPhone = formatPhoneNumberIntl(phone).split(" ")
    const code = fullPhone.splice(0,1)[0]
    const data = {
      email: email.trim(),
      phone_idx: code,
      phone: fullPhone.join("")

    }
    const result = await fetchAPIClientsAPIPost("clients/", null, data);
    if (
      (result.status === 400 &&
        result.data.email[0] === "Ya existe Client con este email.") ||
      result.status === 201
    ) {
      localStorage.setItem("phone", `${code} ${fullPhone.join("")}`)
      dispatch({
        type: "@email/add",
        payload: {
          email: email.trim(),
        },
      });
      getServices();
      navigate("../services/");
    }
  };

  const errorHandler = () => {
    setShowModal(null);
  };

  const changeLanguageHandler = (value) => {
    i18n.changeLanguage(value);
    getServices();
  };
  return (
    <div className={classes.container}>
      <CustomModal
        open={!!showModal}
        handleClose={errorHandler}
        modalInfo={{ title: "Error", description: showModal?.message }}
      />
      {/* <div className={classes.content}> */}
        <div className={classes.welcome}>
          <h1 id={classes["welcome"]}>Wikpis Marketing Digital</h1>
          <div className={classes["welcome-dinamic"]}>
            {t("WELCOME.COTIZADOR")}
          </div>
          <form onSubmit={submitHandler}>
            <div className={classes.containerPhone}>
              <label>{t("CLIENT.CLIENT-INFORMATION-PHONE")} </label>
              <div className={classes.fieldContainer}>
              <PhoneInput
                international
                defaultCountry="CO"
                value={phone}
                onChange={changePhoneHandler}
              />
              </div>
            </div>
            <TextInput
              label={t("WELCOME.INGRESAR_CORREO")}
              placeholder={t("WELCOME.PLACEHOLDER_CORREO")}
              changed={setActiveBtn}
              value={email}
              setValue={setEmail}
            />
            <div className={classes.selects}>
              <SelectInput
                label={t("WELCOME.IDIOMA")}
                options={[
                  { value: "es", label: "Español" },
                  { value: "en", label: "English" },
                ]}
                active={i18n.language}
                change={changeLanguageHandler}
              />
              <SelectInput
                label={t("WELCOME.MONEDA")}
                options={optionValues}
                change={getCoin}
                active={i18n.language === "en" ? "USD" : "COP"}
                type="coin"
              />
            </div>
            <div className={classes.button}>
              <Button text={t("WELCOME.BTN_EMPEZAR")} disabled={activeBtn} />
            </div>
          </form>
        </div>
    </div>
  );
};

export default WelcomePage;
