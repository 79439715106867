import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
// import { useSelector } from 'react-redux';

import SocialAdmin from "./SocialAdmin";
import SocialItemsGroup from "../SocialItems/SocialItemsGroup";
import AditionalServices from "./AditionalServices";

// import NumberInput from "../Common/Inputs/NumberInput"

import useAmount from "../../hooks/useAmount";

import classes from "./SocialGroup.module.css";
import AditionalServicesCard from "./AditionalServicesCard";

import CustomModal from "../CustomModal/CustomModal";

import useStorage from "../../hooks/useStorage";

const SocialGroup = (props) => {
  const { activeSocial, activeAdmin, activeAditional } = useStorage();
  const socialNetwork = props.item.sub_items.filter(
    (item) => item.item_type === "social"
  );
  const dispatch = useDispatch()
  /** News functionalities */
  // const storageAmount = useSelector(state => state.numPost)
  // const { amount, increase, decrement, reset } = useAmount(storageAmount >=0 ? storageAmount : 0);
  // const totalAmount = useAmount(activeAdmin.reduce((s, c) => s + c.amount, 0));
  const [amount, setAmount] = useState(
    activeAdmin.reduce((a, b) => a + b.amount, 0)
  );
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState({ title: "", description: "" });
  const handleOpen = (e) => {
    if (e.target.id === "amount") {
      setMsg({
        title: "Número total de publicaciones",
        description: "Descripción total de post",
      });
    } else {
      setMsg({
        title: "Servicios adicionales",
        description: "Selecciona esta opción si requieres agregar servicios que no están en las descripciones anteriores",
      });
    }
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  let adminItems;

  if (activeSocial.length === 1) {
    if (activeSocial[0].social === "Tik Tok") {
      adminItems = props.item.sub_items
        .filter((item) => item.item_type === "social_admin")
        .filter(
          (item) =>
            item.name === "Diseños gráficos animados o videos" ||
            item.name === "Animated graphic designs or videos"
        );}}

  const findX = activeSocial.find(item => item.social == 'X')
  
  if (findX){
    adminItems = props.item.sub_items.filter(
      (item) => item.item_type === "social_admin"
    );
  } else {
    adminItems = props.item.sub_items
        .filter((item) => item.item_type === "social_admin")
        .filter(
          (item) =>
            item.name !== "Publicación de Trinos" &&
            item.name !== "Tweet publication"
        );
  }

  const sheduleItems = props.item.sub_items.filter(
    (item) => item.item_type === "shedule"
  );
  const aditionalItems = props.item.sub_items.filter(
    (item) => item.item_type === "aditional"
  );
  const aditionalTextHandler = (e) => {
    setText(e.target.value);
  };
  useEffect(() => {
    if (text.trim().length > 0) {
      dispatch({
        type: "@aditional/add",
			payload: {
			  content: text,
        name: "Social Media",
			  id: 888
			},
      });
    }else{
		dispatch({
			type: "@aditional/remove",
				payload: {
					id: 888
				}
		})
	}
  }, [text]);

  // const toStorageHandler = (num) => {
  // 	dispatch({
  //         type: '@numPost/add',
  //         payload: {
  //             numPost: num
  //         }
  //     })
  // }
  return (
    <div className={classes.social}>
      <div className={classes.line}></div>
      <p className={classes.section}>{t("SOCIAL.INFO1")}</p>
      <SocialItemsGroup items={socialNetwork} activeItems={activeSocial} />
      <div className={classes.line}></div>
      {/* <div className={classes['amount-post']}>
				<div className={classes["amount-post-group-help"]}>
					<span>{t("SOCIALADMIN.NUMPOST")}</span>
					<div id="amount" className={classes["radio-generic-help"]}  onClick={handleOpen}></div> 
				</div>
				<NumberInput
					amount={amount}
					increase={increase}
					decrement={decrement}
					reset={reset}
					toStorage={toStorageHandler}
					total={totalAmount.amount}
				/>
			</div> */}
      <p className={classes.section}>{t("SOCIALADMIN.TITLE")}</p>
      {adminItems.map((item, index) => (
        <SocialAdmin
          key={index}
          title={item.name}
          price={item.price}
          description={item.description}
          id={item.id}
          amount={amount}
          setAmount={setAmount}
          // totalAmount={totalAmount}
          active={activeAdmin.find((a) => a.id === item.id)}
        />
      ))}
      <div className={classes.totalAmount}><span>{t("SOCIAL.TOTALPOST")}</span>{amount}</div>
      <p className={classes.section}>{t("SOCIAL.HORARIOS")}</p>
      <AditionalServicesCard
        current={sheduleItems[0]}
        active={activeAditional.find((a) => a.social === sheduleItems[0]?.name)}
      />

      <div
        className={classes["amount-post-group-help"]}
        style={{ marginTop: "30px" }}
      >
        <p className={classes.section}>{t("SOCIAL.SERVICIOS")}</p>
        <div
          id="aditional"
          className={classes["radio-generic-help"]}
          onClick={handleOpen}
        ></div>
      </div>
      <p className={classes.addService}>{t("SOCIAL.ADICIONALES")}</p>
      <AditionalServices aditional={aditionalItems} active={activeAditional} />
      <div className={classes["aditional-text"]}>
        <p className={classes.section}>{t("SOCIALADMIN.NOTE")}</p>
        <textarea onChange={aditionalTextHandler} placeholder={t("SOCIAL.ADICIONALES-PLACEHOLDER")}/>
      </div>
      <CustomModal open={open} handleClose={handleClose} modalInfo={msg} />
    </div>
  );
};

export default SocialGroup;
