import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ItemsPrice from './ItemsPrice'

import classes from "./QuoteSummary.module.css"

export const GetTotal = (data) => {
    let total = 0
    let suma = 0
    const currency = data.currency
    if (data.socials.length > 0){
        total = data.socials.reduce((total, current) => total+= current.price*current.amount, 0)
    }
    let total_sum = data.items.map((item) => {
        suma += Number(item.base_price)
        if (item.items){
            let itemSum = item.items.reduce((total, current) => current.selected ? total += current.price: total, 0)
            suma += itemSum
        }
        return suma
    })
    total += total_sum[total_sum.length - 1]
    return (total*currency).toLocaleString(undefined, {maximumFractionDigits:2})
}

function QuoteSummary(props) {
    const [show, setShow] = useState(false)
    const [total, setTotal] = useState(0)
    const socials = useSelector(state => state.social)
    const currency = useSelector(state => state.currency) // Conversor a dolar
    const dataTotal = {
        items: props.items, socials: socials, currency: currency 
    }
    
    
    const { t } = useTranslation()
    
    
    
    // let suma = 0 
    const showSummaryHandler = () => {
        setShow(!show)
    }  
    
    // useEffect(() => {
    //     /* Obtenemos los items del primer servicio seleccionado almacenado en la base de datos 
    //         Con useEffect ejecutamos la llamada a la API cada que el componente se 
    //         renderice
    //     */
    //    const total = GetTotal(dataTotal)
    //     setTotal(total)
    // }, [dataTotal])

    if (show) {
        return <div className={classes.container}>
            <div className={classes.backdrop} onClick={showSummaryHandler}></div>
            <div className={classes.details}>
                <h4 className={classes.title}>{t("SUMMARY.SUMMARY")}</h4> 
                {/* {props.items.map(item => <ItemsPrice key={item.id} item={item} />)} */}
                <h4 className={classes.total}><span>{t("SUMMARY.TOTAL")}</span><span>{total}</span></h4>
            </div>
            <div className={classes.modal2}>
                <div>
                    <h4>{t("SUMMARY.TOTAL")}</h4>
                    <span>{total}</span>
                </div>
                <button className={classes.btn} onClick={showSummaryHandler}></button>
            </div>
        </div>
    }else{
        return <div className={classes.container} onClick={props.onConfirm} >
            <div className={classes.modal}>
                <div>
                    <h4>{t("SUMMARY.TOTAL")}</h4>
                    <span>{total}</span>
                </div>
                <button className={classes.btn} onClick={showSummaryHandler}></button>
            </div>
        </div>
    }

    
}

export default QuoteSummary