import { useTranslation } from 'react-i18next';
import classes from "./textInput.module.css"

const TextInput = (props) => {
    const { t } = useTranslation()
    const changeHandler = (e) => {
        props.setUserInformation(
            {...props.userInformation, ...{[props.name]: e.target.value}}
        )
    }
    return <div className={classes['text-input']}>
        {props.invalid ? <span className={classes.invalid}>{t("CLIENT.REQUIRED")}</span>:null}
        <label>{props.label}</label>
        <input 
            type="text" 
            placeholder={props.placeholder} 
            onChange={changeHandler}
        />
    </div>
} 

export default TextInput