import { useTranslation } from 'react-i18next';  

import ClientForm from "./clientForm";
import AuthorizationItem from "../Common/Inputs/AuthorizationsItem"
import ServiceDescription from "../ServicesDetails/ServiceDescription";
import Deductions, { DeductionCode } from "../Common/Deductions"

import classes from "./clientInformation.module.css";

const ClientInformation = (props) => {
    const { t } = useTranslation()
    const redirectPolicy = () => {
        window.open(`/wikpis-policy`, '_blank')
    }
    const description = <p style={{margin: 0}}>{t("CLIENT.CLIENT-INFORMATION-POLICY-BODY")}<span className={classes['policy']}onClick={redirectPolicy}> {t("CLIENT.CLIENT-INFORMATION-POLICY-LINK")}</span>.</p>
    const question1 = t("CLIENT.CLIENT-INFORMATION-AUTHORIZATION-1")
    const question2 = t("CLIENT.CLIENT-INFORMATION-AUTHORIZATION-2")
    let deduction = true
    if (props.items.length === 1 && (props.items[0].name==="Desarrollo Web" || props.items[0].name==="Web Development")){
        deduction = false
    }
    
    return <div className={classes['client-information']}>
        <ClientForm 
            setUserInformation={props.setUserInformation}
            userInformation={props.userInformation}
            invalid={props.invalid}
        />
        <ServiceDescription title={t("CLIENT.CLIENT-INFORMATION-POLICY-TITLE")} description={description} stateStyle={{fontSize: '20px'}}/>
        <AuthorizationItem question={question1}/>
        <AuthorizationItem question={question2}/>
        {deduction ? 
            <Deductions 
                setUserInformation={props.setUserInformation}
                userInformation={props.userInformation}
            />: null
        }
        <DeductionCode 
            setUserInformation={props.setUserInformation}
            userInformation={props.userInformation}
        />

    </div>
}

export default ClientInformation;