import CardWrapper from "../../ui/wrappers/CardWrapper"
import classes from "./GenericInput.module.css"

export default function GenericInput ({typeInput, id, text, activeIndex, option, onSelectTemplate, onSelected}){

    if (typeInput === 'radio'){
        return (
            <div className={classes["generic-input-radio"]} onClick={onSelectTemplate.bind(this, option)}>
                <label>{text}</label>
                <input id={id} type={typeInput}  checked={activeIndex} readOnly/>
            </div>
        )
    }else if(typeInput === 'checkbox'){
        return (
            <div className={classes["generic-input-checkbox"]} style={{padding: "0"}} >
                <div className={classes["generic-input-check"]} onClick={onSelected.bind(this, option)}>
                    <label >{text}</label>
                    <div>
                        <input  type={typeInput} checked={activeIndex} readOnly/>
                        <span className={classes["checkmark"]}></span>
                    </div>
                </div>
            </div>
        )
    }
}