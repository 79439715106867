
import { useState } from "react"

import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';

// import useStorage from '../../hooks/useStorage'
import { useElement } from "../../hooks/useElement";

import Header from '../Common/Header'
import Progress from './Progress'
import ServiceDescription from "./ServiceDescription"
import ButtonsNextBack from '../Common/ButtonsNextBack'
import Summary from '../Common/summary/Summary'

import classes from "./ServicesDetails.module.css"

let index = 0

const ServicesDetails = (props) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    // const {activeServices, webServices} = useStorage()
    const {pages, activeServices, webServices} = useElement()
    const [element, setElement] = useState(pages[index])
    
    const btnBackHandler = (e) => {
        e.preventDefault()
        if (index > 0){
            index = index - 1
            setElement(pages[index])
            
            
        }else{  
            navigate("/services")
        }
    }

    const btnNextHandler = (e) => {
        e.preventDefault()
        if (index < pages.length-1){ 
            if (element.name === 'Marketing Digital'){
               const selectedItem = activeServices.find(services => services.name === element.name).items?.find(item => item.selected)
               if (selectedItem){
                   index += 1
               }else{
                window.alert("Please select a service")
               }
            }else if (element.name === 'Desarrollo Web' || element.name === 'Web Development'){
                if (Object.keys(webServices).length === 0 || Object.keys(webServices.webTypeSelected).length === 0 || Object.keys(webServices.template).length === 0){ 
                    window.alert("Please select a service")
                }else{
                    index += 1
                }
            }
            setElement(pages[index])
        }
        else{
            index=0
            navigate("/summary")
        }
    }
    return <div className= {classes.container}>
        <div className= {classes.elements}>

            { activeServices.length > 1 ? (
                <div className= {classes.progress}>
                    <Header step="Paso 2 de 3" message={t("CUSTOM-SERVICES.CUSTOM")}/>
                    <Progress items={activeServices} index={index} title={element?.name}/>
                </div>
                ) : <div className= {classes['custom-services-description']}>
                    <Header step="Paso 2 de 3" message={t("CUSTOM-SERVICES.CUSTOM")}/>
                </div>
            }   
            <div className= {classes['custom-services-description']}>
                <ServiceDescription title={element?.name} description={element?.description} stateStyle={{fontSize: '36px'}}/>
            </div>
            {element.element}
            <ButtonsNextBack onBtnBack={btnBackHandler} onBtnNext={btnNextHandler} disabled/>
        </div>
        <Summary />
    </div>
}

export default ServicesDetails