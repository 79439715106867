import { useSelector } from 'react-redux';

export function FormatPrice (price, conversion=true){
    const currency = useSelector(state => state.currency) // Conversor a dolar
    // const currency = 1
    if (!conversion){
        if (currency !== 1){
            return price.toLocaleString('en-US',{
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
            })
        }
        else{
            return `${price.toLocaleString('es-ES',{
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 2,
            }).slice(0, -7)}`
        }
    }
    
    let total = 0
    if (currency !== 1){
        total = (price*currency*2).toLocaleString('en-US',{
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        })
    }
    else{
        total = `${price.toLocaleString('es-ES',{
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 2,
        }).slice(0, -7)}`
    }
    return total

}