import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

fetch('https://ipapi.co/json/').then(res => res.json()).then(res => {
    window.localStorage.setItem('country', res.country_name)
    return res.country_name
})

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: window.localStorage.getItem('country') === 'Colombia' ? 'es' : 'en',
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

export default i18n;