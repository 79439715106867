export const servicesReducer = (services = [], action) => {
  switch (action.type) {
    case "@service/init":
      return [action.services];

    case "@service/selected":
      const id = action.action.id;
      return [
        services[0].map((service) => {
          if (service.id === Number(id)) {
            return {
              ...service,
              selected: !service.selected,
            };
          }
          return service;
        }),
      ];

    case "@service/items":
      return [
        services[0].map((service) => {
          if (service.id === Number(action.payload.serviceId)) {
            if (
              service.items &&
              service.items.findIndex(
                (item) => item.id === action.payload.item.id
              ) === -1
            ) {
              return {
                ...service,
                items: [...service.items, action.payload.item],
              };
            } else {
              return { ...service, items: [action.payload.item] };
            }
          }
          return service;
        }),
      ];

    case "@service/itemSelected":
      const serviceId = action.payload.serviceId;
      const itemId = action.payload.itemId;
      const service =
        services[0][
          services[0].findIndex((service) => service.id === serviceId)
        ];
      const modificado = service.items.map((item) =>
        item.id === itemId ? { ...item, selected: !item.selected } : item
      );
      return [
        services[0].map((service) =>
          service.id === serviceId ? { ...service, items: modificado } : service
        ),
      ];
    default:
      return services;
  }
};

export const socialReducer = (socials = [], action) => {
  switch (action.type) {
    case "@social/init":
      return [];
    case "@social/add":
      if (socials.length === 0) {
        return [action.payload];
      } else {
        const item = socials.findIndex(
          (social) => social.id === action.payload.id
        );
        if (item === -1) {
          return [...socials, action.payload];
        }
        return socials.map((social) => {
          if (social.id === action.payload.id) {
            return action.payload;
          }
          return social;
        });
      }
    case "@social/remove":
      return socials.filter((social) => social.id !== action.payload.id);
    default:
      return socials;
  }
};

export const aditionalServices = (aditional = [], action) => {
  switch (action.type) {
    case "@aditional/init":
      return [];
    case "@aditional/add":
      const item = aditional.findIndex(i => i.id === action.payload.id)
      if (item !== -1){
        const items = aditional.filter(i => i.id !==action.payload.id)
        return [...items, action.payload]
      }
      else return [...aditional, action.payload];
    case "@aditional/remove":
      return aditional.filter((item) => item.id !== action.payload.id);
    default:
      return aditional;
  }
};
