import { useSelector } from 'react-redux';

export default function useTotal(items) {
    const currency = useSelector(state => state.currency) // Conversor a dolar
    let total = items.reduce((total, item) => total + item.total, 0)
    let totalFormat = 0
    if (currency !== 1){
        total = (total*currency*2)
        totalFormat = total.toLocaleString('en-US',{
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        })
    }
    else{
        totalFormat = total.toLocaleString('es-ES',{
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 2,
        }).slice(0, -7)
    }
    return {
        total,
        totalFormat
    }
}

export function useFormatPrice (){
    const currency = useSelector(state => state.currency) // Conversor a dolar
    const convertPrice = (price, convertion=true) => {

        if (!convertion){
            if (currency !== 1){
                return price.toLocaleString('en-US',{
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                })
            }
            else{
                return price.toLocaleString('es-ES',{
                    style: 'currency',
                    currency: 'COP',
                    minimumFractionDigits: 2,
                }).slice(0, -7)
            }
        }
        
        let total = 0
        if (currency !== 1){
            total = (price*currency*2).toLocaleString('en-US',{
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
            })
        }
        else{
            total = `${price.toLocaleString('es-ES',{
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 2,
            }).slice(0, -7)}`
        }
        return total
    }

    return {
        convertPrice
    }

}