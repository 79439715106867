
// import { useState, useRef } from "react";
// import ModalPrice, { Bubble } from "../../ui/ModalPrice/ModalPrice";
import classes from "./NumberInput.module.css"


const NumberInput = (props) => {
    // const [animationMinus, setAnimationMinus] = useState(false)
    // const [animationPlus, setAnimationPlus] = useState(false)

    // const [plus, setPlus] = useState([]);
    // const cleanPlus = useRef((id) => {
    //     setPlus((currentPlus) => currentPlus.filter((plus) => plus !== id));
    // });

    // const [down, setDown] = useState([]);
    // const cleanDown = useRef((id) => {
    //     // setPlus((currentDown) => currentDown.filter((down) => down !== id));
    // });

    const stepDown = (e) => {
        e.preventDefault();
        // setDown((prevDown) => [...prevDown, new Date().getTime()])
        // if (props.toStorage) {
        //     if (props.total === props.amount) return;
        //     props.toStorage(props.amount - 1)
        // }

        if (props.amount > 0 && props.amountBase) {
            props.setAmount(props.amountBase-1)

        }

        props.decrement()

    }
    const stepUp = (e) => {
        e.preventDefault();
        // setPlus((prevPlus) => [...prevPlus, new Date().getTime()])
        // if (props.toStorage) {
        //     props.toStorage(props.amount + 1)
        // }
        // if (props.totalAmount) {
        //     if (props.totalAmount.amount < props.amountBase) {
        //         props.increase()
        //         props.totalAmount.increase()

        //     } else props.errorMsg(`La distribución de publicaciones supera el máximo ingresado (${props.amountBase})`)
        // } else {
            props.increase()
            if (props.amountBase || props.amountBase === 0){
                props.setAmount(props.amountBase+1)
            }

        // }

    }
    const valueHandler = (e) => {
        let value = e.target.value
        if (props.setAmount){
            props?.setAmount(props.amountBase-props.amount+Number(value))
        }
        props.reset(Number(value))

    }
    return <div className={classes.container} >
        <label>
            {props.label}
        </label>
        <div className={classes.inputnumber}>
            <input className={classes.quantity} name="quantity" value={props.amount} type="text" onChange={valueHandler} />
            <div className={classes.btnNumbers}>
                <button onClick={stepDown}><div className={classes.minus}>
                    {/* {down.map((id) => (
                        <Bubble onAnimationEnd={cleanDown.current} price={`-${props.price}`} key={id} id={id} />
                    ))} */}
                </div></button>
                <button onClick={stepUp}><div className={classes.plus}>
                    {/* {plus.map((id) => (
                        <Bubble onAnimationEnd={cleanPlus.current} price={`+${props.price}`} key={id} id={id} />
                    ))} */}
                </div></button>
            </div>
        </div>
    </div>
}

export default NumberInput