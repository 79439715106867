import TextInput from "./textInput";
import { useTranslation } from 'react-i18next';
import './styles.css'
import classes from "./clientForm.module.css";

const ClientForm = (props) => {
    const { t } = useTranslation()

    return <div className={classes['client-form']}>
        <h4 className={classes['client-title']}>{t("CLIENT.CLIENT-INFORMATION-TITLE")}</h4> 
        <form className={classes['client-inputs']}>
            <TextInput 
                label={t("CLIENT.CLIENT-INFORMATION-COMPANY")} 
                placeholder={t("CLIENT.CLIENT-INFORMATION-COMPANY-PLACEHOLDER")} 
                setUserInformation={props.setUserInformation}
                userInformation={props.userInformation}
                name='company'
                invalid={props.invalid.find(item => item[0]==='company') ? true : false}
            />
            <TextInput 
                label={t("CLIENT.CLIENT-INFORMATION-NAME")} 
                placeholder={t("CLIENT.CLIENT-INFORMATION-NAME-PLACEHOLDER")} 
                setUserInformation={props.setUserInformation}
                userInformation={props.userInformation}
                name='name'
                invalid={props.invalid.find(item => item[0]==='name') ? true : false}
            />
            <TextInput 
                label={t("CLIENT.CLIENT-INFORMATION-ROLE")} 
                placeholder={t("CLIENT.CLIENT-INFORMATION-ROLE-PLACEHOLDER")} 
                setUserInformation={props.setUserInformation}
                userInformation={props.userInformation}
                name='role'
                invalid={props.invalid.find(item => item[0]==='role') ? true : false}
            />
        </form>  
    </div>
}

export default ClientForm