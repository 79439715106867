import { useState } from "react"
import { useTranslation } from 'react-i18next';
import InputRadio from "../ui/input/Input";
import { FormatPrice } from "../../hooks/utilities";
import useTotal from "../../hooks/useTotal";
import { getItemsSelected } from "../../hooks/useStorage";
import CustomModal from "../CustomModal/CustomModal";
import { validateDiscountCode } from "../../APIClient/fetchAPI"


import classes from "./Deductions.module.css"  


const Deductions = (props) => {
    const { t } = useTranslation()
    const [active, setActive] = useState(null)
    const items = getItemsSelected()
    // const total2 = items.reduce((total, item) => total + item.total, 0)
    const {total} = useTotal(items)
    
    const activeItem = (value) => {
        setActive(value)
        props.setUserInformation(
            {...props.userInformation, ...{deduction: value}}
        )
    }
    const itemsDeductions = [
        {
            value: "5", 
            text: t("CLIENT.CLIENT-INFORMATION-DEDUCTION-1"), 
            amount: FormatPrice(total*0.05, false)
        },
        {
            value: "10", 
            text: t("CLIENT.CLIENT-INFORMATION-DEDUCTION-2"), 
            amount: FormatPrice(total*0.1, false)
        }
    ]
    return <div className={classes.deduction} >
        <span className={classes.title}>{ t("CLIENT.CLIENT-INFORMATION-DEDUCTION-TITLE")}</span>
        {itemsDeductions.map((item, index) => <DeductionCard 
            key = {item.value}
            value={item.value}
            onChangeRadio={activeItem}
            checked={item.value === active ? true: false}
            text={item.text}
            amount={item.amount} 
            />
        )}
    </div>
}

const DeductionCard = (props) => {
    const {value, onChangeRadio, checked, text, amount} = props

    const radioHandler = (e) => {
        onChangeRadio(e.target.id)
    }
    return <div id={value} className={classes.deductionCard} onClick={radioHandler}>
        <InputRadio
            type="radio"
            value={value}
            checked={checked}
            onChange={onChangeRadio}
        />
        <div id={value} onClick={radioHandler} className={classes["deduction-card-text"]}>
            <span id={value} onClick={radioHandler}>{text}</span>
            <span id={value} onClick={radioHandler}>{amount}</span>
        </div>
    </div>
}

export const DeductionCode = (props) => {
    const { t } = useTranslation()
    const [code, setCode] = useState(null)
    const [open, setOpen] = useState(false);
	const handleClose = () => setOpen(false);


    const changeText = (e) => {
        setCode(e.target.value)
    }

    const deductionCodeHandler = () => {
        validateDiscountCode(code).then(data => {
            if (data.data?.id && data.data?.active){
                props.setUserInformation(
                    {...props.userInformation, ...{deductionCode: data.data?.code}}
                )
            }else {
                setOpen(true)
                props.setUserInformation(
                    {...props.userInformation, ...{deductionCode: 'na'}}
                )
            }
        })
    }
    return <div className={classes["deduction-card"]}>
        <div>
            <span>{t("CLIENT.CLIENT-INFORMATION-DEDUCTION-CODE-TITLE")}</span>
            <input type="text" placeholder={t("CLIENT.CLIENT-INFORMATION-DEDUCTION-CODE-PLACEHOLDER")} onChange={changeText}/>
        </div>
        <button onClick={deductionCodeHandler}>{t("CLIENT.CLIENT-INFORMATION-DEDUCTION-BUTTON")}</button>
        <CustomModal open={open} handleClose={handleClose} modalInfo={{ title: "Código de descuento", description: "Código de descuento no encontrado o caducado"}} />
    </div>
}

export default Deductions