import { useTranslation } from 'react-i18next';
import classes from "./Footer.module.css"

const Footer = () => {
    const { t } = useTranslation()
    return <div className={classes.footer}> 
        <div className={classes.child} >
            <b> &copy; Wikpis Marketing Digital.</b> {t('FOOTER.DERECHOS')}
        </div>
    </div>
}

export default Footer