import classes from "./ButtonsNextBack.module.css"
import { useTranslation } from 'react-i18next';  

const ButtonsNextBack = (props) => {
    const { t } = useTranslation()
    let btn_next;
    if (!props.disabled){
        btn_next = <button className={classes['btn-disabled']} disabled >{t("GLOBAL.BUTTON-NEXT")}</button>
    } else {
        btn_next =  <button className={classes['btn-next']} onClick={props.onBtnNext}>{t("GLOBAL.BUTTON-NEXT")}</button>
    }
    return <div className={classes['container-buttons']} >
        <button className={classes['btn-back']} onClick={props.onBtnBack}>{t("GLOBAL.BUTTON-BACK")}</button>
        {btn_next}
    </div>
}

export default ButtonsNextBack