import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InputRadio from "../../components/ui/input/Input"
import classes from "./AditionalServicesCard.module.css"
import NumberInput from "../../components/Common/Inputs/NumberInput"
import useAmount from "../../hooks/useAmount";

const AditionalServicesCard = (props) => {
    const { t } = useTranslation()
    const [checked, setChecked] = useState(props.active ? true : false)
    const dispatch = useDispatch()
    const { amount, increase, decrement, reset } = useAmount(props.current.amount);

    useEffect(() => {
        if ((props.current.name === 'Cubrimiento' || props.current.name === 'Guideline service') && checked) {
            dispatch({
                type: '@social/add',
                payload: {
                    id: props.current.id,
                    social: props.current.name,
                    price: props.current.price,
                    amount: amount,
                    type: "aditional"
                }
            })
        }
    }, [amount])

    const radioHandler = (e) => {
        setChecked(!checked)
        if (!checked) {
            dispatch({
                type: '@social/add',
                payload: {
                    id: props.current.id,
                    social: props.current.name,
                    price: props.current.price,
                    amount: props.current.amount,
                    type: "aditional"
                }
            })
        }else{
            dispatch({
                type: '@social/remove',
                payload: {
                    id: props.current.id
                }
            })
        }
    }
    return <div>
        <div className={classes.item}>
            <div className={classes.content}>
                <div className={classes.text}>
                    <p className={classes.title}>{props.current?.name}</p>
                    <p>{props.current?.description}</p>
                </div>
                <div className={classes.options}>
                    <label>{t("CLIENT.CLIENT-INFORMATION-AUTHORIZATION-YES")}</label>
                    <InputRadio
                            type="radio"
                            value="yes"
                            checked={checked}
                            onChange={radioHandler}
                        />
                    <label>{t("CLIENT.CLIENT-INFORMATION-AUTHORIZATION-NO")}</label>
                    <InputRadio
                            type="radio"
                            value="no"
                            checked={!checked}
                            onChange={radioHandler}
                        />
                </div>
            </div>
            {(props.current.name === 'Cubrimiento' || props.current.name === 'Guideline service') && checked ? 
                <div className={classes.numeric}>
                    <NumberInput
                        amount={amount}
                        increase={increase}
                        decrement={decrement}
                        reset={reset}
                        price={props.current.price}
                    />
                </div> : null
            }
        </div>
    </div> 
}
    

export default AditionalServicesCard