import { useTranslation } from 'react-i18next';  

import {useFormatPrice} from '../../hooks/useTotal'
import {FormatPrice} from '../../hooks/utilities'
import classes from './ServiceSummary.module.css';

const ServiceSummary = (props) => {
    const { t } = useTranslation()
    const {convertPrice} = useFormatPrice()

    return <div className={classes.serviceSummary}>
        <span className={classes["service-summary-title"]}>
            <b>{t('LANDING.LANDING-SERVICE')} {props.idx}:</b> {props.service.name} 
        </span>
        <div className={classes['service-summary-content']}>
            {props.service.items.map((item, idx) => <Item key={idx} item={item} color="black" ident={0}/>)}
            {props.service.note ? <div><span style={{fontWeight: 'bold'}}>Nota: </span>{props.service.note.name}</div> : null}
        </div>
        <div className={classes['service-line-total']}><span>Sub Total</span><span>{convertPrice(props.service.total)}</span></div>
    </div>
}

const Item = (props) => {
    const {convertPrice} = useFormatPrice()
    
    return <div>
        <div className={classes['item-line']} style={{paddingLeft: `${props.ident}px`, color: props.color}}>
            <span>{props.item.name}</span>
            <span>{props.item.price !== 0 ? convertPrice(props.item.price): null}</span>
        </div>
        {/* {props.item?.items?.map((subItem, idx) => {
            return <div className={classes['sub-item-line']} key={idx}>
                {props.item.id === 48 ? 
                    <>
                        <span>{subItem.name}</span>
                        <span>{subItem.price !== 0 ? subItem.price : null}</span>
                    </>:
                    <>
                        <span>{subItem.name} {subItem.amount > 1 ? `x ${subItem.amount}`: ''}</span>
                        <span>{subItem.price !== 0 ? subItem.price* subItem.amount : null}</span>
                    </>
                }
            </div>
        })} */}
        {props.item?.items?.map((subItem, idx) => <Item item={subItem} key={idx} color={props.color} ident={props.ident+30}/>)}
    </div>
}

export default ServiceSummary