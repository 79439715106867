
// import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';  
import { useNavigate } from 'react-router-dom'; 


// import ItemsPrice from "../Common/ItemsPrice"
import {SummaryItem} from "../Common/summary/Summary"
// import {GetTotal} from '../Common/QuoteSummary';
import classes from "./clientQuote.module.css";

const ClientQuote = (props) => {
    const {items, totalFormat} = props;


    const { t } = useTranslation()
    const navigate = useNavigate()
    
    const btnBackHandler = (e) => {
        e.preventDefault()
        navigate("../custom-services")
    }
    return <div className={classes.quoteInfo}>

        <h4 className={classes['client-title']}>{t("CLIENT.CLIENT-SUMMARY")}</h4> 
        {/* {services.map(item => <ItemsPrice key={item.id} item={item} />)} */}
        {items.map((item, i) => {
            return <SummaryItem key={i} item={item} color="black" ident={10} isShow={true}/>
        })}
        <div className={classes['client-total']}>
            <h4>Total:</h4><h4>{totalFormat}</h4>
        </div>
        <div className={classes.containerButton}>
            <div className={classes['client-quote-back']} onClick={btnBackHandler}>{t("CLIENT.CLIENT-BACK")}</div>
        </div>
        <p>Estos valores son aproximados, el valor final podrá ser mayor o menor al estimado según tus requerimientos finales una vez uno de nuestros asesores te contacte.</p>
    </div>
}

export default ClientQuote