import { useState } from 'react'

import classes from "./TextInput.module.css";

const TextInput = props => {
    const {value, setValue, changed, label, placeholder} = props
    const inputChangeHandler = (e) => {
        setValue(e.target.value)       
        if(e.target.value.trim().length === 0){
            changed(false)
        }else{
            changed(true)
        }
    }
    return <div className={classes['text-input']} >
        <label>
            {label}
        </label>
        <input 
            type="email" 
            placeholder={placeholder} 
            value={value}
            onChange={inputChangeHandler}
        />
    </div>
}

export default TextInput