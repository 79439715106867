import { useState } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'; 
import { useNavigate } from 'react-router-dom'; 
import useStorage from '../../hooks/useStorage';

import { fetchAPIClientsAPIPost } from '../../APIClient/fetchAPI'

import Header from "../Common/Header"
import ClientQuote from "./clientQuote"
import ClientsInformation from "./clientInformation"

import {formatServices, validateUserInfo } from "../../utilities/formatItems"

import classes from "./clientPage.module.css"
import useTotal from '../../hooks/useTotal'
import {getItemsSelected} from "../../hooks/useStorage"



const ClientPage = () => {
    const { t, i18n } = useTranslation()
    // const { activeServices, activeSocial, activeAdmin, activeAditional} = useStorage()
    const inputs = useStorage()
    const items = getItemsSelected()
    // const total2 = items.reduce((total, item) => total + item.total, 0)
    const {totalFormat} = useTotal(items)

    const navigate = useNavigate()
    const currency = useSelector(state => state.currency)
    // const socialServices = [].concat(activeSocial, activeAdmin, activeAditional)
    const email = useSelector(state => state.email)
    const [userInformation, setUserInformation] = useState({company: '', name: '', role: ''})
    const [invalid, setInvalid] = useState([])
    
    const [price, setPrice] = useState(0)

    const btnBackHandler = (e) => {
        e.preventDefault()
        navigate("../custom-services")
    }

    const submitHandler = async(e) => {
        e.preventDefault()
        const others = {
            email,
            userInformation,
            language: i18n.language,
            currency,
            totalFormat
        }
        let valid = validateUserInfo(userInformation, setInvalid)
        if (valid === true) {
            const data = formatServices(inputs, others)
            const result = await fetchAPIClientsAPIPost("quotes/", null, data);   
            window.open(`/quote-summary/?lang=${data.language}&token=${result.data.token}`, '_blank')
            navigate(`/`);
        }    
    }
    return <div className={classes['client-page']}>
        <Header step="Paso 3 de 3" message={t("CLIENT.TITLE")} />
        <div className={classes['client-grid']}>
            <div className={classes['client-grid-left']}>
                <ClientQuote setPrice={setPrice} totalFormat={totalFormat} items={items}/>
                {/* <div className={classes['client-description']}>
                    <ServiceDescription title={t("CLIENT.CLIENT-QUOTE-TITLE")} description={t("CLIENT.CLIENT-QUOTE-BODY")} stateStyle={style}/>
                </div> */}
            </div>
            <div className={classes['client-grid-right']}>
                <ClientsInformation 
                    setUserInformation={setUserInformation}
                    userInformation={userInformation}
                    price={price}
                    invalid={invalid}
                    items={items}
                />
            </div>
        </div>
        <div className={classes['client-information-button']}>
            <button className={classes['client-information-button-back']} onClick={btnBackHandler}>{t("GLOBAL.BUTTON-BACK")}</button>
            <button className={classes['client-information-button-next']} onClick={submitHandler}>{t("CLIENT.CLIENT-BUTTON")}</button>
        </div>
    </div>
}

export default ClientPage