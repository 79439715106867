import { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';  
import { useDispatch } from "react-redux";
import { getItemsSelected } from '../../hooks/useStorage'
import useTotal from "../../hooks/useTotal"

import {useFormatPrice} from '../../hooks/useTotal'

import Section from './Section'
import ServiceSummary from './ServiceSummary'
import classes from "./LandingPage.module.css"



import { fetchAPIQuotesGet } from '../../APIClient/fetchAPI'
 
const LandingPage = () =>{
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch();
    const items = getItemsSelected()
    const {totalFormat} = useTotal(items)
    const {convertPrice} = useFormatPrice()

    const [results, setResult] = useState(null)
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
    const language = new URLSearchParams(search).get('lang');
    const date = new Date(results ? results.date : null).toLocaleDateString('es-ES', {year: 'numeric', month: 'long', day: '2-digit'});
    let deduction, deductionCode
    const content = [
        {
            title: <span></span>,
            content: <p className={classes['landing-page-content-1']}>{t('LANDING.LANDING-CONTENT-1')}</p>
        },
        {
            title: <span className={classes['landing-page-content-title']}>{t('LANDING.LANDING-CONTENT-TITLE-2')}</span>,
            content: <p className={classes['landing-page-content']}>{t('LANDING.LANDING-CONTENT-2')}</p>
        },
        {
            title: <span className={classes['landing-page-content-title']}>{t('LANDING.LANDING-CONTENT-TITLE-3')}</span>,
            content: <p className={classes['landing-page-content']}>{t('LANDING.LANDING-CONTENT-3')}</p>
        },
        {
            title: <span className={classes['landing-page-content-title']}>{t('LANDING.LANDING-CONTENT-TITLE-4')}</span>,
            content: <p className={classes['landing-page-content']}>{t('LANDING.LANDING-CONTENT-4')}</p>
        }
    ]
    if (results){
        deduction = results.total*(Number(results.deduction)/100)
        deductionCode = results.total*(Number(results.deductionCode)/100)
    }
    useEffect(() => {
        fetchAPIQuotesGet(token, language).then(result => { 
            dispatch({
                type: "@currency/add",
                payload: {
                  currency: result.data.currency,
                },
              });
            i18n.changeLanguage(result.data.language);
            setResult(result.data)
        }).catch(error => {setResult(null)}); 
        
    }, [token]);
    return <div>
    {!results ? <h1>Token expirado</h1> : 
        <div className={classes.landingPage}>
            <span className={classes['landing-page-title']}>{t('LANDING.LANDING-TITLE')} <span>{results.client.company}</span></span>
            <div className={classes['landing-page-options']}>
                <span className={classes['landing-page-name']}>{t('LANDING.LANDING-GREEAT')}, {results.client.name}</span>
                
            </div>
            <Section 
                content={content[0].content}
            />
            <hr />
            <div className={classes['landing-page-message-header']}>
                <span>Medellin, {date}</span>
                <span>{t('LANDING.LANDING-SIRS')}, {results.client.company}</span>
                <span>E.S.M</span>
            </div>
            <Section
                title={content[1].title} 
                content={content[1].content}
            />
            <div className={classes['landing-page-services']}>
                <span className={classes["landing-page-sub-title"]}>{t('LANDING.LANDING-PROPOSAL_TITLE')}</span>
                {results.quote.map((quote, idx) => <ServiceSummary 
                    key={idx} 
                    service={quote} 
                    idx={idx+1} 
                    totalFormat={totalFormat}
                />)}  
                {/* <SummaryItem key={i} item={item} color="white" ident={10} isShow={showInfo}/> */}
                <div className={classes['landing-page-total']}>
                    <div className={classes['landing-page-total-quote']}>
                        <span>Total</span>
                        <span>{results.language === 'en' ? `$${results.total.toLocaleString('en-US')}` : convertPrice(results.total, false)}</span>
                    </div>
                    {results.deduction !== 0 ? 
                        <div className={classes['landing-page-deduction']}>
                            <span>{t('LANDING.LANDING-DISCOUNT')} {`${results.deduction}%`}</span>
                            <span>{results.language === 'en' ? `-$${deduction.toFixed(2).toLocaleString('en-US')}`: convertPrice(deduction, false)}</span>

                        </div>
                    : null}
                    {
                        results.deductionCode !== 0 ?
                        <div className={classes['landing-page-deduction']}>
                            <span>{t('LANDING.LANDING-DISCOUNTCODE')} {`${results.deductionCode}%`}</span>
                            <span>{results.language === 'en' ? `-$${deductionCode.toFixed(2).toLocaleString('en-US')}`: convertPrice(deductionCode, false)}</span>
                        </div>
                        : null
                    }
                    <div className={classes['item-line-total-quote']}>
                        <span>Total</span>
                        <span>{results.language === 'en' ? `$${(results.total-deduction-deductionCode).toFixed(2).toLocaleString('en-US')}` : convertPrice(results.total-deduction-deductionCode, false)}</span>
                    </div>
                </div>
            </div> 
            {/* {
                true ? <div>  
                <Section 
                title={content[2].title}
                content={content[2].content}
                />
                <Section 
                    title={content[3].title}
                    content={content[3].content}
                /> </div>: null
            } */}
        </div>
    }
    </div>
}

export default LandingPage;