import classes from "./Button.module.css"

const Button = props => {
    let btn_next;
    if (props.disabled) {
        btn_next = <button className={classes['button-active']} >{props.text} </button>
    }else {
        btn_next = <button className={classes['button-disabled']} disabled>{props.text}</button>
    }
    return <div className={classes.button}>{btn_next}</div>    
    
}

export default Button