
import classes from "./Progress.module.css" 

const Progress = (props) => {
    const { items, index, title} = props
    return <>
        <div className={classes.allScreen}>

            <ol className={classes['steplist']}>
                {items.map(
                    (item, itemIndex) => (
                        <li key={item.id} className={item.name === title ? classes.active: index > itemIndex ? classes.end: null}>
                            {index >= itemIndex ? <i/>:null}
                            <div className={classes.text}>
                                <span>{item.name}</span>
                            </div>
                        </li>
                    )
                )}
            </ol>
        </div>
        <div className={classes.responsive}>
            {items.map(
                (item, itemIndex) => (
                    // index === itemIndex
                    <div key={item.id} className={ item.name === title ? `${classes['service-card']} ${classes['active-card']}`:  index > itemIndex ? `${classes['service-card']} ${classes['inactive-card']}` : classes['service-card']}>
                        {item.name}
                    </div>
                )
            )}
        </div>
     </>
}

export default Progress