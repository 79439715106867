import Items from "../ServicesDetails/Items"

import { useDispatch } from 'react-redux';
import useStorage from '../../hooks/useStorage'

const DigitalMarketing = () => {
    const dispatch = useDispatch()
    const {activeServices} = useStorage()
    const marketing = activeServices.filter(activeService => activeService.name === 'Marketing Digital')[0]
    const items = marketing.items.sort((a, b) => Number(b.active) - Number(a.active)); // b - a for reverse sort)

    const addItemSelected = (item) => {
        dispatch({type: '@service/itemSelected', payload:{
                serviceId: marketing.id,
                itemId: item.id
            }
        })
    }

    return items.map((item) => (
        <Items 
            key={item.id}  
            service={marketing.id}
            item={item} 
            itemsSelected={addItemSelected} 
            active={item.selected ? true : false}
            disabled={item.active}
        />))
}

export default DigitalMarketing