import classes from "./CardAdminWrapper.module.css"

export default function CardAdminWapper({children, active}) {
    return (
        <div className={classes['card-admin-wrapper']}>
            <div className={active ? `${classes['card-admin-content']} ${classes['active-content']}` : classes['card-admin-content']}>
                {children}
            </div>
            <div className={active ? `${classes['card-admin-overlay']} ${classes['active']}` : classes['card-admin-overlay']}>
            
            </div>
        </div>
    )
}