import { useState, useEffect } from 'react'

import SocialGroup from "../Items/SocialGroup"
import RadioButtonOption from "../Common/RadioButtonOption/RadioButonOption"


const Items = (props) => {
    const [checked, setChecked] = useState(false)
    let components = [
        { service: props.service, item: props.item.id, component: <SocialGroup key={props.item.id} item={props.item} /> }
    ]

    const radioHandler = (e) => {
        setChecked(!checked)
        props.itemsSelected(props.item)
        
    }

    const ActiveItem = () => {
        setChecked(props.active)
    }

    useEffect(() => {
        /* Obtenemos los items del primer servicio seleccionado almacenado en la base de datos 
            Con useEffect ejecutamos la llamada a la API cada que el componente se 
            renderice
        */
        ActiveItem()
    })
    return <div>
        <RadioButtonOption name={props.item.name} description={props.item.description} disabled={!props.disabled} checked={checked} radioHandler={radioHandler}/>

        {checked ? components.map(component => (
            checked ? component.component : <h1 key={props.item.id}>Sin diseño</h1>
        )) : null}
    </div>
}

export default Items