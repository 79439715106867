import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import NumberInput from "../Common/Inputs/NumberInput";
import useAmount from "../../hooks/useAmount";
import CustomModal from "../CustomModal/CustomModal";

import classes from "./SocialAdmin.module.css";

const SocialAdmin = (props) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	// const [error, setError] = useState(false);
	// const handleCloseError = () => setError(false);

	/** News functionalities */
	const { amount, increase, decrement, reset } = useAmount(
		props.active?.amount
	);

	useEffect(() => {
		dispatch({
			type: "@social/add",
			payload: {
				amount,
				price: props.price,
				social: props.title,
				id: props.id,
				type: "admin",
			},
		});
	}, [amount]);

	return (
		<div className={classes.container}>
			<div className={classes.title}>
				<div className={classes.text}> {props.title}</div>
				<div className={classes.help} onClick={handleOpen}></div>
			</div>

			<NumberInput
				label={t("SOCIALADMIN.CANTIDAD")}
				amount={amount}
				increase={increase}
				decrement={decrement}
				reset={reset}
				amountBase={props.amount}
				setAmount={props.setAmount}
				// errorMsg={setError}
				price={props.price}
			/>
			<p className={classes.fraction}>
				{amount}/{props.amount}
			</p>
			<CustomModal
				open={open}
				handleClose={handleClose}
				modalInfo={{ title: props.title, description: props.description }}
			/>
			{/* <CustomModal
				open={!!error}
				handleClose={handleCloseError}
				modalInfo={{ title: "Error", description: error }}
			/> */}
		</div>
	);
};

export default SocialAdmin;
