import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const useStorage= () => {
    const activeServices = useSelector(state => state.services)[0]?.filter(selector => selector.selected===true)
    const socialServices = useSelector(state => state.social)
    const webServices = useSelector(state => state.web)
    const webItems = useSelector(state => state.webItems)
    const webAdmin = useSelector(state => state.webAdmin)
    const aditionals = useSelector(state => state.aditional)

    const activeSocial = socialServices.filter(social => social.type === 'social')
    const activeAdmin = socialServices.filter(social => social.type === 'admin')
    const activeAditional = socialServices.filter(social => social.type === 'aditional')

    return {
        activeServices: activeServices || [],
        activeSocial,
        activeAdmin,
        activeAditional, 
        webServices, 
        webItems,
        webAdmin,
        aditionals
    }
}


function MarketingItems() {
    const { t } = useTranslation()
    const { activeServices, activeSocial, activeAdmin, activeAditional } = useStorage()
    let total = 0
    let post = 0
    const Marketing = activeServices.find(services => services.name === 'Marketing Digital') 
    let data = []
    if (Marketing) {
        data.push({name: t("SUMMARY.BASE"), price: Marketing?.base_price})
        const activeItems = Marketing.items.filter((item) => item.selected === true)
        activeItems.map(item => {
            if (item.name === 'Redes Sociales' || item.name === 'Social Networking'){
                const socials = activeSocial.map((social, index) => {return {name: index === 0 ? `${t("SUMMARY.INCLUDE-NETWORK")} (${social.social})`: `${t("SUMMARY.ADITIONAL-NETWORK")} (${social.social})`, price: index === 0 ? 0 : social.price}})
                const admin = activeAdmin?.filter((admin) => admin.amount > 0).map((admin) => {return {name: admin.amount > 1 ? `${admin.social} x${admin.amount}` : admin.social, price: admin.price * admin.amount}})
                
                const aditional = activeAditional.map((aditional) => {{
                    if (aditional.social === 'Cubrimiento' || aditional.social === 'Guideline service'){
                        return {name: `${aditional.social} x ${aditional.amount}`, price: aditional.price*Number(aditional.amount)}
                    }
                    return {name: aditional.social, price: aditional.price}
            }})
                let items = socials.concat(admin, aditional)
                total = items.reduce((total, item) => total + Number(item.price), 0) + item.price
                items = [  
                    {name: t("SUMMARY.BASE-INCLUDED"), price: item?.price},
                    {name: t("SUMMARY.NETWORK"), price: 0, items:socials}, {name: t("SUMMARY.POST"), price: 0, items: admin}
                ].concat(aditional)
                // post = numPost - activeAdmin?.filter((admin) => admin.amount > 0 && admin.price > 0).reduce((a, b) => a + Number(b.amount), 0)
                data.push({name: item.name, price: total, items: items})

            }
            return true;
        })
        return {
            name: Marketing?.name,
            price: Marketing?.base_price + total + (post*12000),
            items: data,
            total: Marketing?.base_price + total + (post*12000)
        }
    }
    return {};
}

function WebDevelopment() {
    const { t } = useTranslation()
    let { activeServices, webServices, webItems } = useStorage()
    let data = []
    let total = 0
    const Web = activeServices.find(services => services.name === 'Desarrollo Web' || services.name === 'Web Development') 
    if (Web) {
        data.push({name: t("SUMMARY.BASE"), price: Web?.base_price})
        let typeWeb = []
        if (Object.keys(webServices).length > 0) {
            if  (Object.keys(webServices?.webTypeSelected).length > 0) {
                typeWeb.push({name: webServices.webTypeSelected?.name, price: webServices.webTypeSelected?.price})
                total += webServices.webTypeSelected?.price
            }
    
            if  (Object.keys(webServices?.template).length > 0) {
                typeWeb.push({name: webServices.template?.name, price: webServices.template?.price})
                total += webServices.template?.price
            }
        
        }
        
       
            const ti = webItems.filter(item => item.item_group === "none")[0]
            if (ti){
                typeWeb.push({name: ti.name, price: ti.price})
                total += ti.price
                webItems = webItems.filter(item => item.item_group !== "none")
            }
            data.push({name: t("SUMMARY.TYPE-PAGE"), price: total, items: typeWeb})
            let total2 = 0
            let complements = []
            webItems.map(item => {
                if (item.data){
                    item = {
                        ...item, 
                        items: item.data.map(d => {
                            return {name: d.value}
                        }),
                        name: item.data.length > 1 ? `${item.name} x${item.data.length}` : item.name,
                        price: item.price*item.data.length
                    }
                }
                if (item.amount){
                    complements.push({name: item.amount > 1 ? `${item.name} x${item.amount}` : item.name, price: item.amount > 1 ? item.price*item.amount : item.price, items: item.items})
                    total2 += item.price*item.amount
                }else{
                    complements.push({name: item.name, price: item.price ? item.price:0, items: item.items})
                    total2 += item.price
                }
            })
            data.push({name: "Items", price: total2, items: complements})
        
        const Total = data.reduce((acc, item) => acc += item.price, 0)
        return {
            name: Web?.name,
            price: Total,
            items: data,
            total: Total
        }
    }
    return {};
}

function WebAdministration() {
    const { activeServices, webAdmin } = useStorage()
    const admin = activeServices.find(services => services.name === 'Administración Web' || services.name === 'Web Administration') 
    if (admin) {
        return {
            name: admin?.name,
            price: admin?.base_price + webAdmin?.price + admin.base_price,
            items: [webAdmin],
            total: Object.keys(webAdmin).length > 0 ? webAdmin?.price + admin.base_price : admin.base_price
        }
    }
    return {};
}

export function getItemsSelected() {
    // let total = 0
    let result = []
    const marketing = MarketingItems()
    const web = WebDevelopment()
    const admin = WebAdministration()
    if (Object.keys(marketing).length > 0) {
        result.push(marketing)
    }
    if (Object.keys(web).length > 0) {
        result.push(web)
    }
    if (Object.keys(admin).length > 0) {
        result.push(admin)
    }

    return result
}



export default useStorage;