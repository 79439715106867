import { useState, useEffect } from "react"
import { useDispatch } from "react-redux";

import useStorage from '../../../hooks/useStorage'

import CardWrapper from "../../ui/wrappers/CardWrapper"
import ComboWrapper from "../../ui/wrappers/ComboWrapper"
import GenericInput from "../../Common/Inputs/GenericInput"

import classes from "./WebPageOptions.module.css"

export default function WebPageOptions({option, activeIndex, onSelectTemplate, disabled}) {
    const { webItems } = useStorage()
    
    const [activeOption,setActiveOption] = useState({id: 0, active: false})
    let customStyles = {
        borderRadius: "10px",
        backgroundColor: "#ccc",
        pointerEvents: "none",
        opacity: "0.6",
        marginBottom: "50px"
    }
    const {name, sub_items} = option
    const dispatch = useDispatch();
    useEffect(() =>{
        const active = webItems?.filter(item => item.item_group === 'none')[0]
        if (active) setActiveOption({id: active.id, active: true})
        else setActiveOption({id:0, active: false})
    }, [webItems])
    const selectFieldHandler = (item) => {
        dispatch({type: '@webItems/add-template', payload: {name: item.name, price: item.price, id: item.id, item_group: item.item_type, relates_to: item.relates_to}})
        if (item.id === activeOption.id) {
            setActiveOption({id: item.id, active: !activeOption.active})
        }else{
            setActiveOption({id: item.id, active: true})
        }
    }

    if (disabled){
        customStyles = {
            borderRadius: "10px",
            margin: "30px auto"
        }
    }
    return (
        <div className={classes.webPageOptions}>
            <CardWrapper customStyle={customStyles} >
                <GenericInput typeInput="radio" text={name} activeIndex={activeIndex} onSelectTemplate={onSelectTemplate} option={option}/>
            </CardWrapper>
            {sub_items.length > 0 && activeIndex ? <ComboWrapper customStyle={{borderRadius: "10px"}}>
                {sub_items.map((item, idx) => <GenericInput key={idx} typeInput="checkbox" id={idx} text={item.name} option={item} onSelected={selectFieldHandler} activeIndex={activeOption.id === item.id ? activeOption.active : false}/>)}
                </ComboWrapper> : null
            }
        </div>
    )
}