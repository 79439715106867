import { useState } from "react";
import { useTranslation } from 'react-i18next'; 

import classes from './AuthorizationItem.module.css'

const AuthorizationItem = (props) => {
    const { t } = useTranslation()
    const [checked, setChecked] = useState(false)

    const radioHandler = (e) => {
        setChecked(!checked)
        // props.itemsSelected(props.item)
    }
    return <div className={classes.AuthorizationItem}>
        <span>{props.question}</span>
        <div className={classes["authorization-item-options"]}>
            <label>{t("CLIENT.CLIENT-INFORMATION-AUTHORIZATION-YES")}</label>
            <input type="radio" value="yes" checked={checked} onChange={radioHandler}/>
            <label>{t("CLIENT.CLIENT-INFORMATION-AUTHORIZATION-NO")}</label>
            <input type="radio" value="no" checked={!checked} onChange={radioHandler}/>
        </div>
    </div>
}

export default AuthorizationItem