
import classes from "./ServiceDescription.module.css"

const ServiceDescription = (props) => {
    return <div className={classes['services-description']}>
        <h2 style={props.stateStyle}>{props.title}</h2>
        <div id={classes.description}>{props.description}</div>
    </div>
}

export default ServiceDescription