import { useState } from "react";
import { useDispatch } from 'react-redux';
import { DOMAIN } from "../../../APIClient/endPoints";
import classes from "./SocialItem.module.css";

export default function SocialItem({ item, isActive }) {
	const dispatch = useDispatch()
	const [active, setActive] = useState(isActive);

	const selectedItemHandler = () => {
		if (active) {
			/**Remove item from storage */
			dispatch({
				type: '@social/remove',
				payload: {
					id: item.id
				}
			})
		}else{
			/**Add item to storage */
			dispatch({
				type: '@social/add',
				payload: {
					id: item.id,
					social: item.name, 
					price: item.price,
					type: 'social'
				}
			})
		}
		setActive(!active)
	}

	return (
		<div className={classes["social-item-container"]} onClick={selectedItemHandler}>
			<div className={classes["social-item-elemment"]}>
				<img src={`${DOMAIN}${item.image}`} />
				<span>{item.name}</span>
			</div>
			<div
				className={
					active
						? `${classes["social-item-select"]} ${classes["active"]}`
						: classes["social-item-select"]
				}
				onClick={selectedItemHandler}
			></div>
		</div>
	);
}
