import { useState } from "react";

const useAmount = (init = 0) => {
  const [amount, setAmount] = useState(init);

  const increase = () => setAmount(amount + 1);
  const decrement = () => {
    if (amount > 0)setAmount(amount - 1);
  };
  const reset = (value) => setAmount(value);
  const clear = () => setAmount(0);

  return {
    amount,
    increase,
    decrement,
    reset,
    clear,
  };
};

export default useAmount;
