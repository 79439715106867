export const currentReducer = (currency=1, action) => {
    switch (action.type) {
        case '@currency/add':
            return action.payload.currency
        
        default: return currency
    }
}

export const email = (email="", action) => {
    switch (action.type) {
        case '@email/add':
            return action.payload.email
        default: return email
    }
}

export const numPost = (numPost=0, action) => {
    switch (action.type) {
        case '@numPost/init':
            return 0
        case '@numPost/add':
            return action.payload.numPost
        default: return numPost
    }
}