import { useState } from "react";
import CardWapper from "../../ui/wrappers/CardWrapper"
import InputRadio from "../../ui/input/Input"
import CustomModal from "../../CustomModal/CustomModal"

import { useTranslation } from "react-i18next";
import classes from "./RadioButtonOption.module.css"

const RadioButtonOption = (props) => {
    const { t } = useTranslation();
    const {name, disabled, checked, radioHandler, description, width, widthOption} = props
    const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
    return <CardWapper customStyle={{ borderRadius: "10px", marginBottom: "50px", maxWidth: width, boxShadow: !disabled ? '4px 4px 30px 1px rgba(0, 0, 0, 0.2)':null }}>
        <div
            className={
                !disabled
                    ? classes["radio-container"]
                    : `${classes["radio-container"]} ${classes.disabled}`
            }
            style={{width: widthOption}}
        >
            <div className={classes["radio-name"]}>
                {name} {disabled ? `(${t("GLOBAL.SOON")})`: null}<div className={classes["radio-generic-help"]}  onClick={handleOpen}></div> 
            </div>
            <div className={classes["radio-group"]}>
                <div className={classes["radio-group-label"]} >
                    <InputRadio
                        styles={{opacity: disabled ? '0.4' : null}}
                        type="radio"
                        value="yes"
                        checked={checked}
                        onChange={() => (true)}
                    />
                    <div id="yes" className={classes['radio-group-label-front']} onClick={radioHandler}></div>
                </div>
            </div>
            <CustomModal open={open} handleClose={handleClose} modalInfo={{ title: name, description: description }} />
        </div>
    </CardWapper>
}

export default RadioButtonOption