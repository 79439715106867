import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redirect, Link, useNavigate } from 'react-router-dom'
import classes from "./Navigation.module.css"
import logo from "../../assests/logo/logowikpis.png"
import PRINTER from "../../assests/images/printer.svg"

const Navigation = () => {
    const landingPage = window.location.href.includes('quote-summary/')
    const navigate = useNavigate()
    const { t } = useTranslation()
    const linkHandler = () => {
        if (window.confirm(t('NAVBAR.ALERT'))){
            navigate('/')
        }
        return false
    }
    const printHandler = (e) => {
        e.preventDefault();
        window.print()
    }

    return <div className={classes.container}>
        <div className={classes.navigation}>
            <div className={classes.logo} >
                <span onClick={linkHandler}>
                    <img src={logo} alt="Wikpis Logo" />
                </span>
                <div className={classes.line}></div>
                <p>{t('NAVBAR.COTIZADOR')}</p>
            </div>
            {!landingPage ?
                <>
                    <button className={classes.btnToggle}><div className={classes.toggle}></div></button>
                    <ul className={classes.nav}>
                        <li>
                        <span onClick={linkHandler} className={classes.back}>{t('NAVBAR.VOLVER')}</span>
                        </li>
                    </ul>
                </>: <div className={classes['landing-page-print']}>
                    <button onClick={printHandler}><span className={classes['landing-page-icon']}><img src={PRINTER}/></span><span className={classes['landing-page-print-text']}>{t('LANDING.LANDING-PRINT')}</span></button>
                </div>
            }
            
        </div>
    </div>
}

export default Navigation