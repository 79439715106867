export const indexReducer = (index=0, action) => {
    switch (action.type) {
        case '@index/increment':
            return action.payload.numServices - 1 > index ? index + 1 : index

        case '@index/decrement':
            return index > 0 ? index-1 : index
        
        default: return index
    }
}