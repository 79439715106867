import { useState,useEffect } from "react";
import { useDispatch} from "react-redux";
import { useTranslation } from 'react-i18next';  

import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";

import Input from "../../ui/input/Input";
import {useFormatPrice} from '../../../hooks/useTotal'


import classes from "./CustomMarketing.module.css";

const CustomMarketing = ({ current, active }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [message, setMessage] = useState(t("ADITIONAL.MARKETING-MSG"));
  const [value, setValue] = useState(active ? active.price : current.price);
  const [show, setShow] = useState(false);
  const {convertPrice} = useFormatPrice()


  useEffect(() => {
    if (show){
      dispatch({
        type: "@social/add",
        payload: {
          id: current.id,
          price: active ? active.price : current.price,
          social: current.name,
          type: current.item_type,
        },
      });
    }else{
      dispatch({
        type: "@social/remove",
        payload: {
          id: current.id,
        },
      });
    }
  },[show, value])
  

  const valueChangedHandler = (e, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
    if (newValue < 350000) {
      setMessage(t("ADITIONAL.MARKETING-MSG-ERROR"));
    } else if (newValue === 350000) {
      setMessage(t("ADITIONAL.MARKETING-MSG"));
    } else setMessage("");

    dispatch({
      type: "@social/add",
      payload: {
        id: current.id,
        price: newValue,
        social: current.name,
        type: current.item_type,
      },
    });
  };

  const marketingHandler = () => {
    setShow(!show)
    setValue(current.price)
  }

  return (
    <div className={classes.customMarketing}>
      <div className={classes["custom-marketing-container"]}>
        <p className={classes.title}>{current.name}</p>
        <p className={classes.description}>{current.description}</p>
        <div className={classes["custom-no-marketing"]}  onClick={marketingHandler}>
          <div>
            <Input type="checkbox" checked = {show} />
          </div>
          <span>{t("ADITIONAL.MARKETING")}</span>
        </div>
        {show ? (
          <>
            <p>{t("ADITIONAL.WARNING")}</p>
            <div className={classes['custom-slider-container']}>
              <div className={classes['custom-slider']}>
                <CustomSlider
                  value={value}
                  min={100000}
                  max={1000000}
                  step={10000}
                  // aria-label="Default"
                  // valueLabelDisplay="auto"
                  onChange={valueChangedHandler}
                />
              </div>
              <div className={classes["custom-slider-value"]} >
                <span>{t("ADITIONAL.MARKETING-VALUE")}</span>
                <span>{`${convertPrice(value)}`}</span>
              </div>
            </div>
            <div
                className={
                  value < 350000
                    ? `${classes["custom-marketing-message"]} ${classes["custom-marketing-error"]}`
                    : classes["custom-marketing-message"]
                }
              >
                {message}
              </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const CustomSlider = styled(Slider)`
  color: var(--purple);
  height: 8px;

  & .MuiSlider-rail {
    color: #dbdbdb;
  }

  & .MuiSlider-thumb {
    color: white;
    box-shadow: 2px 2px 3px 2px #dbdbdb;
  }

  & .MuiSlider-valueLabel {
    color: white;
    background: var(--orange);
  }
  & .MuiSlider-valueLabelOpen {
    background: var(--orange);
  }
`;

export default CustomMarketing;
