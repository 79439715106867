import { useState } from "react";
import { useTranslation } from 'react-i18next';

import { getItemsSelected } from "../../../hooks/useStorage";
import useTotal from "../../../hooks/useTotal"
import { FormatPrice } from '../../../hooks/utilities';

import classes from "./Summary.module.css";


const Sumary = () => {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const items = getItemsSelected()
    const {totalFormat} = useTotal(items)
    const width = window.innerWidth


    const isShow = () => {
        if (items.length <= 1){
            return true
        }else if(items.length === 2){
            const data = items.filter(i => i.name === 'Administración Web' || i.name === 'Web Administration')
            if (data.length > 0) return true
            else return false
        }
        return false
    }
    
    const showInfo = isShow() 

    const showHandler = () => {
        if (show) setShow(false)
    }

    return <>
        {
            show ?  
            <div className={classes["summary-backdrop"]} onClick={() => setShow(false)}></div> : null
        }
        <div className={classes["summary-container"]} style={show & width < 525 ? {width: '100%', top: '0px'} :  {}} >
            
            {
                show ? 
                <>
                    
                    <div className={classes["summary-container-group"]}>
                        <div className={classes["summary-details"]} >
                        <div className={classes["summary-detail-close"]} onClick={showHandler}></div>
                            <div className={classes["summary-title"]}>{t("SUMMARY.SUMMARY")}</div>
                            {items.map((item, i) => {
                                return <SummaryItem key={i} item={item} color="white" ident={10} isShow={showInfo}/>
                            })}
                            <div className={classes["summary-details-total"]}>
                                <span className={classes["summary-redux-total-title"]}>{t("SUMMARY.SUMMARY-TOTAL")}</span>
                                <span className={classes["summary-redux-total-amount"]}>{totalFormat}</span>
                            </div>
                        </div>
                        <div className={classes["summary-redux-wrapper-group"]}  onClick={showHandler}>
                            <div 
                                className={
                                    `${classes["summary-redux"]}`
                                } 
                                onClick={() => setShow(!show)}>
                                <span className={classes["summary-redux-total-container"]}>
                                    <span className={classes["summary-redux-total-title"]}>{t("SUMMARY.SUMMARY-TOTAL")}</span>
                                    <span className={classes["summary-redux-total-amount"]}>{totalFormat}</span>
                                </span>
                                <div className={`${classes["summary-redux-button"]} ${classes["summary-redux-button-show"]}`}><div ></div></div>
                            </div>
                        </div>
                    </div>
                </>
                : 
                <div className={classes["summary-redux-wrapper"]}  onClick={showHandler}>
                    <div 
                        className={
                            `${classes["summary-redux"]}  ${classes["summary-redux-hide"]}`
                        } 
                        onClick={() => setShow(!show)}>
                        <span className={classes["summary-redux-total-container"]}>
                            <span className={classes["summary-redux-total-title"]}>{t("SUMMARY.SUMMARY-TOTAL")}</span>
                            <span className={classes["summary-redux-total-amount"]}>{totalFormat}</span>
                        </span>
                        <div className={classes["summary-redux-button"]}><div></div><span>{t("SUMMARY.VIEW-MORE")}</span></div>
                    </div>
                </div>
            } 
        </div>
    </>
}

export const SummaryItem = ({item, color, ident, isShow}) => {
    const [show, setShow] = useState(isShow)
    let mark = false
    if (item.items){
        mark = true
    }
    return (
        <>
            <div className={classes['summary-item-container']} style={{paddingLeft: `${ident}px`, color: color}} onClick={() => setShow(!show)}>
                <span className={classes['summary-item-group']}>
                    {
                        mark ? 
                        <div 
                            className={classes['summary-item-btn-down']} 
                            style={{backgroundColor: color? color : 'black'}}
                            onClick={() => setShow(!show)}
                        ></div> : 
                        null}
                    <div>{item?.name}</div>
                </span>
                <div>{item?.price ? FormatPrice(Number(item?.price)):null}</div>
            </div>
            { 
                show && item.items?.map((item, i) => <SummaryItem key={i} item={item} color={color} ident={ident+30} isShow={isShow}/>)
            }
        </>
    )
}

export default Sumary;