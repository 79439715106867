import { useState } from "react"
import { useNavigate} from "react-router-dom"
import ButtonsNextBack from '../Common/ButtonsNextBack'
import Header from '../Common/Header'
import Progress from "./Progress"
import QuoteSummary from '../Common/QuoteSummary'
import ServiceDescription from './ServiceDescription'
import SocialGroup from '../Items/SocialGroup'

import classes from "./ItemsService.module.css"



const ItemsService = () => {
    let index = Number(window.sessionStorage.getItem('index'))
    let selectedServices = JSON.parse(window.sessionStorage.getItem('items'))
    const [subIndex, setSubIndex] = useState(0)
    const navigate = useNavigate()

    // const GetComponentItemsService = () => {
    //     const itemsSelected = selectedServices[index]['subItems']
    //     if (itemsSelected){
                      
    //     }
    //     const component = 
    // }

    const btnBackHandler = (e) => {
        e.preventDefault()
        navigate("../custom-services")
    }

    const btnNextHandler = (e) => {
        e.preventDefault() 
        setSubIndex(subIndex+1)
        if (subIndex < selectedServices[index]['subItems'].length-1){
        }else{
            if (index < selectedServices.length-1){
                window.sessionStorage.setItem("index", index+1)
                navigate("/custom-services")
            }else{  
                navigate("/services")
            }
        }   
    }
    return <div className={classes.container}>
        <QuoteSummary items={selectedServices}/>
        <div className={classes.item}>
            <Header step="Paso 2 de 3" message="Personaliza tus servicios"/>
            <Progress items={selectedServices}/>
            <ServiceDescription title={selectedServices[index].name} description={selectedServices[index].description} />
            <h3 id={classes.title}>{selectedServices[index]['subItems'][subIndex].name}</h3>
            {selectedServices[index]['subItems'][subIndex].name === 'Redes Sociales' ? <SocialGroup />: <h2>Trabajando en ello</h2>}
            <ButtonsNextBack onBtnBack={btnBackHandler} onBtnNext={btnNextHandler} disabled/>
        </div>
    </div>
}

export default ItemsService