import { useState, useEffect } from "react";

import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import useStorage from '../../../hooks/useStorage'

import Card from "../../Card/Card";
import WebPageOptions from "../WebPageOptions/WebPageOptions";

import classes from "./WebPage.module.css";

export default function WebPage() {
	const { t } = useTranslation()
  const dispatch = useDispatch();
  const {activeServices, webServices, webItems} = useStorage()
  const web = activeServices.filter(activeService => activeService.name === 'Desarrollo Web' || activeService.name === 'Web Development')[0]
  const webType = web.items.filter(item => item.item_type === 'none')
  const [options, setOptions] = useState(web.items.filter(item => item.item_type === 'template'))

  const [activeCard, setActiveCard] = useState(webServices.webTypeSelected?.id)
  const [activeIndex, setActiveIndex] = useState(webServices.template?.id)


  const selectWebTypeHandler = (item) => {
    setActiveCard(item?.id)
    if (item?.name === 'Web Informativa' || item?.name === 'Informative Website'){
        setOptions(web.items.filter(item => item.item_type === 'template').filter(item => item.name !== 'Shopify (e-commerce)' && item.name !== 'Shopify (Para tiendas online)'))
    }else{
        setOptions(web.items.filter(item => item.item_type === 'template').filter(item => item.name !== 'Drupal'))
    }
    let webTypeSelected = Object.assign({}, item)
    let template = Object.assign({}, options.find(item => item.id===activeIndex))
    const deleteField = ['description', 'item_type', 'active', 'image', 'sub_items']
    deleteField.forEach(field => delete webTypeSelected[field])
    deleteField.forEach(field => delete template[field])

    const payload = {webTypeSelected, template}
    if (activeCard !== webTypeSelected.id){
      dispatch({ type: "@web/init"})
      dispatch({ type: "@webItems/init"})
      setActiveIndex(0)
    }
    dispatch({ type: "@web/add", payload: payload})
  }
  
  const selectTemplateHandler = (item) => {
    setActiveIndex(item?.id )
    let template = Object.assign({}, item)
    let webTypeSelected = Object.assign({}, webType.find(item => item.id===activeCard))
    const deleteField = ['description', 'item_type', 'active', 'image', 'sub_items']
    deleteField.forEach(field => delete webTypeSelected[field])
    deleteField.forEach(field => delete template[field])

    const payload = {webTypeSelected, template}
    dispatch({ type: "@webItems/remove", payload: {relates_to: activeIndex}})
    dispatch({ type: "@web/add", payload: payload})

  }

  useEffect(() => {
    selectWebTypeHandler(webServices.webTypeSelected)
  }, [])
  return (
    <div>
      <div className={classes.pageTypeTitle}>{t("WEB.CHOSE-TYPE")}</div>
      <div className={classes["webpage-card-container"]}>
        {webType.map((item, idx) => (
          <Card key={idx} element={item} active={item.id===activeCard} onSelectWebType={selectWebTypeHandler}/>
        ))}
      </div>
      <div className={classes["webpage-options-container"]}>
            {options.map((option, idx) => <WebPageOptions key={idx} disabled={activeCard ? true : false} option={option} id={idx} activeIndex={activeIndex === option.id} onSelectTemplate={selectTemplateHandler}/>)}
      </div>
    </div>
  );
}
