import classes from "./SelectInput.module.css"

const SelectInput = props => {
    const changeEvent = (e) =>{
        props.change(e.target.value)
    }

    return <div className={classes['select-input']} >
        <label>
            {props.label}
        </label>
        <select onChange={changeEvent} defaultValue={props.active}>
            {props.options.map((opt) => <option key={opt.value} value={opt.value} >{opt.label}</option>  
            )}
        </select>
    </div>
}

export default SelectInput