import CardAdminWapper from '../../ui/wrappers/CardAdminWrapper'
import { useDispatch } from "react-redux";
import { FormatPrice } from '../../../hooks/utilities'
import classes from './AdminWebItem.module.css'

const AdminWebItem = ({ item, onActive, active }) => {
    const dispatch = useDispatch();
    const { name, price, description, sub_items } = item
    const selectItem = () => {
        dispatch({
            type: "@webAdmin/add",
            payload: {
                id: item.id,
                name: name,
                price: price
            },
        });
        onActive(item.id)
    }
    return <CardAdminWapper active={active}>
        <div className={classes['admin-web-container']} onClick={selectItem}>
            <div className={classes['admin-web-header']}>
                <div className={classes['admin-web-title']}>{name}</div>
                <div >{`${description}`}</div>
            </div>
            <hr />
            <div className={classes['admin-web-body']}>
                <div > <span className={classes['admin-web-body-span']}>Precio: </span>{`${FormatPrice(price)}`}</div>
                <div >Contine</div>
                {sub_items.map(item => <div key={item.id} className={classes['admin-web-items']}><div className={classes['admin-web-check-item']}></div><span>{item.name}</span></div>)} 
            </div>
        </div>
    </CardAdminWapper>
}

export default AdminWebItem