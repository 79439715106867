import axios from "axios";
import { DOMAIN } from './endPoints'

const request = axios.create({
    baseURL: DOMAIN,
})

/*Clients*/
export const fetchAPIClientsAPIGet = async (endpoint, authToken, data=null) => {
    if (authToken) {
        authToken = `Bearer ${authToken}`  
        request.defaults.headers.common['Authorization'] = authToken  
    }    

    return await request.get(endpoint, data)
}

export const fetchAPIClientsAPIPost = async(endpoint, authToken, data=null) => {
    if (authToken) {
        authToken = `Bearer ${authToken}`
        request.defaults.headers.common['Authorization'] = authToken 
    } 

    return await request.post(endpoint, data).then(
        response=>{
            return response}).catch(err=>{
                return err.response})

}
/*Services*/
export const fetchAPIServicesAPIGet = async(endpoint, authToken, data=null) => {

    if (authToken) {

        authToken = `Bearer ${authToken}`
        request.defaults.headers.common['Authorization'] = authToken 
    } 

    return await axios.get(`${DOMAIN}${endpoint}`, data, {headers:{
        'Access-Control-Allow-Origin': "*"
    }}).then(
        response => {
             return response 
        }).catch(err=>{
            return err.response
        })
}

export const fetchAPIQuotesGet = async(token, lang, authToken, ) => {
    if (authToken) {
        authToken = `Bearer ${authToken}`
        request.defaults.headers.common['Authorization'] = authToken 
    } 
    return await request.get('quote-get/', { params: { lang, token}}).then(
        response => {
             return response 
        }).catch(err=>{
            return err.response
        })
}

export const fetchAPIServiceAPIGet = async(id, data=null) => {
    return await request.get(`services/${id}/`, {params: data, headers: null}).then(
        response => {
             return response 
        }).catch(err=>{
            return err.response
        })
}

export const fetchAPIServicesItemsGet = async(data) => {
    return await request.get("items/items-services/", {params: data, headers: null}).then(
        response => {
             return response 
        }).catch(err=>{
            return err.response
        })
}

export const fetchAPIItemRelatesTo = async(data, lang) => {
    return await request.get(`items/relates-to/?lang=${lang}`, {params: data, headers: null}).then(
        response => {
             return response 
        }).catch(err=>{
            return err.response
        })
}

export const validateDiscountCode = async(data) => {
    return await request.get(`bonds/validate/?code=${data}`).then(
        response => {
             return response 
        }).catch(err=>{
            return err.response
        })
}
