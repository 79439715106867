import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import classes from "./CustomModal.module.css";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: "none",
	boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
	borderRadius: "15px",
	p: 4,
};

const CustomModal = ({ open, handleClose, modalInfo }) => {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style} style={{width: window.innerWidth > 550 ? 400: "70%"}}> 
				<Typography id="modal-modal-title" variant="h6" component="h2">
					<div className={classes["modal-title"]}>
						<div className={classes["modal-icon"]}></div>
						<div className={classes["modal-text"]}>{modalInfo.title}</div>
						<div className={classes["modal-close"]} onClick={handleClose}>x</div>
					</div>
				</Typography>
				<Typography id="modal-modal-description" sx={{ mt: 2 }}>
					{modalInfo.description}
				</Typography>
			</Box>
		</Modal>
	);
};

export default CustomModal;
