import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import useAmount from "../../../hooks/useAmount";
import useStorage from "../../../hooks/useStorage";

import ComboWrapper from "../../ui/wrappers/ComboWrapper";
import GenericInput from "../Inputs/GenericInput";
import NumberInput from "../Inputs/NumberInput";
import CustomModal from "../../CustomModal/CustomModal"
import RadioButtonOption from "../RadioButtonOption/RadioButonOption";

import classes from "./RadioYesNo.module.css";



const RadioYesNo = ({ item, disabled, width = null, widthOption = null }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch();
	const { webItems, webServices } = useStorage();
	const [checked, setChecked] = useState(
		webItems.find((webItem) => (webItem.relates_to === item.id ? true : false))
	);
	const [text, setText] = useState("");

	const [open, setOpen] = useState(false);
	const [modalInfo, setModalInfo] = useState(false);
	const handleOpen = (item) => {
		setOpen(true)
		setModalInfo({ title: item.name, description: item.description })
	}
	const aditionalTextHandler = (e) => {
		setText(e.target.value);
	};
	useEffect(() => {
		if (text.trim().length > 0) {
			dispatch({
				type: "@aditional/add",
				payload: {
					content: text,
					name: "Desarrollo Web",
					id: 889
				},
			});
		} else {
			dispatch({
				type: "@aditional/remove",
				payload: {
					id: 889
				}
			})
		}
	}, [text]);
	const handleClose = () => setOpen(false);

	const radioHandler = (e) => {
		setChecked(!checked);
		dispatch({ type: "@webItems/remove", payload: { relates_to: item.id } });
	};
	const groupCheck = item.sub_items.filter(
		(item) => item.item_type === "group-check"
	);
	const services = item.sub_items.filter(
		(item) => item.item_type === "services"
	);
	const pageServices = item.sub_items.filter(
		(item) => item.item_type === "page-services"
	);
	const category = item.sub_items.filter(
		(item) => item.item_type === "category"
	);
	const amount = item.sub_items.filter((item) => item.item_type === "amount");
	const languages = item.sub_items.filter(
		(item) => item.item_type === "language-check"
	);
	const extras = item.sub_items.filter((item) => item.item_type === "extra");
	const sends = item.sub_items.filter((item) => item.item_type === "send");

	const selectFieldHandler = (item) => {
		dispatch({ type: "@webItems/add", payload: item });
	};
	return (
		<>
			<RadioButtonOption name={item.name} description={item.description} disabled={disabled} checked={checked} radioHandler={radioHandler} width={width} widthOption={widthOption} />
			{!checked ? null : (
				<>
					{groupCheck.length === 0 ? null : (
						<ComboWrapper customStyle={{ borderRadius: "10px" }}>
							{groupCheck?.map((item, index) => {
								if (webServices.webTypeSelected.name === "Web Informativa" || webServices.webTypeSelected.name === "Informative Website") {
									if (item.name !== 'Catálogo de productos' &&  item.name !== 'Product catalog')
										return <GenericInput
											key={index}
											typeInput="checkbox"
											text={item.name}
											option={item}
											onSelected={selectFieldHandler}
											activeIndex={webItems.find(
												(webItem) => webItem.id === item.id
											)}
										/>
								} else {
									return <GenericInput
										key={index}
										typeInput="checkbox"
										text={item.name}
										option={item}
										onSelected={selectFieldHandler}
										activeIndex={webItems.find(
											(webItem) => webItem.id === item.id
										)}
									/>
								}

							})}
						</ComboWrapper>
					)}
					{/**AQUI ¿Cuantos servivios ofreces? */}
					{services.length === 0 ? null : (
						<ComboWrapper customStyle={{ borderRadius: "10px" }}>
							{services?.map((item) => (
								<NumericField key={item.id} item={item} />
							))}
						</ComboWrapper>
					)}
					{pageServices.length === 0 ? null : (
						<ComboWrapper customStyle={{ borderRadius: "10px" }}>
							{pageServices?.map((item, index) => (
								<GenericInput
									key={index}
									typeInput="checkbox"
									text={item.name}
									option={item}
									onSelected={selectFieldHandler}
									activeIndex={webItems.find(
										(webItem) => webItem.id === item.id
									)}
								/>
							))}
						</ComboWrapper>
					)}
					{/**AQUI Categorías de los productos */}
					{!webServices.template.name.includes("Shopify") ?
						null :
						<>
							{category.length === 0 ? null : (
								<ComboWrapper customStyle={{ borderRadius: "10px" }}>
									{category?.map((item) => (
										<NumericField key={item.id} item={item} />
									))}
								</ComboWrapper>
							)}
							{amount.length === 0 ? null : (
								<ComboWrapper customStyle={{ borderRadius: "10px" }}>
									{amount?.map((item, index) => (
										<NumericField key={item.id} item={item} />
									))}
								</ComboWrapper>
							)}
						</>

					}

					{languages.length === 0 ? null : (
						<ComboWrapper customStyle={{ borderRadius: "10px" }}>
							{languages?.map((item, index) => (
								<GenericInput
									key={index}
									typeInput="checkbox"
									text={item.name}
									option={item}
									onSelected={selectFieldHandler}
									activeIndex={webItems.find(
										(webItem) => webItem.id === item.id
									)}
								/>
							))}
							<div className={classes["radio-generic-container"]}>
								<div className={classes["radio-generic-help-group"]}>
									<label>{t("WEB.MORE-LANGUAGES")}</label>
									<div
										className={classes["radio-generic-help"]}
										onClick={() => handleOpen(item)}
									></div>
								</div>
								<span></span>
								<ExtraField
									item={{
										...item,
										price: languages[0].price,
										...{ item_type: "languages" },
									}}
								/>
							</div>
						</ComboWrapper>
					)}
					{extras.length === 0 ? null : (
						<ComboWrapper customStyle={{ borderRadius: "10px" }}>
							{extras?.map((item, index) => (
								<div className={classes["radio-generic-container"]} key={index}>
									<div className={classes["radio-generic-help-group"]}>
										<label>{item.name}</label>
										<div
											className={classes["radio-generic-help"]}
											onClick={() => handleOpen(item)}
										></div>
									</div>
									<span></span>
									<ExtraField item={item} />
								</div>
							))}
						</ComboWrapper>
					)}
					{sends.length === 0 ? null : (
						<>
							<ComboWrapper customStyle={{ borderRadius: "10px" }}>
								{sends?.map((item, index) => (
									<GenericInput
										key={index}
										typeInput="checkbox"
										text={item.name}
										option={item}
										onSelected={selectFieldHandler}
										activeIndex={webItems.find(
											(webItem) => webItem.id === item.id
										)}
									/>
								))}
							</ComboWrapper>
							{/* <ComboWrapper customStyle={{ borderRadius: "10px" }}>
                                <div className={classes["radio-generic-container"]}>
                                    <label>Agregar empresa de transporte</label>
                                    <span></span>
                                    <ExtraField item={}/>
                                </div>
                            </ComboWrapper> */}
						</>
					)}
					{item.name == 'Creación de contenido de la página web' || item.name == 'Website content creation' ?
						<>
							<div>
								<p className={classes['aditional-text']}>{t('WEB.ADITIONAL-TEXT')}</p>
								<textarea className={classes["aditional-details"]} onChange={aditionalTextHandler} />
							</div>
						</> : null}
				</>
			)}
			<CustomModal open={open} handleClose={handleClose} modalInfo={modalInfo} />
		</>
	);
};

const ExtraFileUnit = (props) => {
	const { parent, item, pages, setPages } = props;
	const [value, setValue] = useState(item.value);
	const dispatch = useDispatch();

	const addNewPageHandler = () => {
		let id;
		while (true) {
			id = Math.floor(Math.random() * 100);
			const unique = pages.find((page) => page.id === id);
			if (!unique) break;
		}
		setPages([...pages, { id, value: "" }]);
	};

	const removePageHandler = () => {
		dispatch({
			type: "@webItems/extras",
			payload: parent,
			input: { id: item.id },
			remove: true,
		});
		setPages(pages.filter((page) => page.id !== item.id));
	};

	const inputChangeHandler = (e) => {
		setValue(e.target.value);
		dispatch({
			type: "@webItems/extras",
			payload: parent,
			input: { id: e.target.id, value: e.target.value },
		});
	};

	return (
		<div className={classes["radio-generic-container-extra"]}>
			<input
				type="text"
				id={item.id}
				placeholder="Agregar extra"
				onChange={inputChangeHandler}
				value={value}
			/>
			<button
				className={classes["extra-generic-container-minus"]}
				onClick={removePageHandler}
			>
				<div className={classes.minus}></div>
			</button>
			<button
				className={classes["extra-generic-container-plus"]}
				onClick={addNewPageHandler}
			>
				<div className={classes.plus}></div>
			</button>
		</div>
	);
};

const ExtraField = ({ item }) => {
	const { webItems } = useStorage();
	const extras = webItems.find(
		(webItem) => webItem.item_type === item.item_type
	);
	const [extraPages, setExtraPages] = useState(
		extras ? extras.data : [{ id: 0 }]
	);
	return (
		<div className={classes["extra-generic-container"]}>
			{extraPages.map((extra) => (
				<ExtraFileUnit
					key={extra.id}
					parent={item}
					item={extra}
					pages={extraPages}
					setPages={setExtraPages}
				/>
			))}
		</div>
	);
};

const NumericField = ({ item }) => {
	const { name } = item;
	const dispatch = useDispatch();
	const { webItems } = useStorage();
	const isActive = webItems.find((webItem) => webItem.id === item.id);
	const amount = useAmount(isActive?.amount);


	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		if (amount.amount > 0) {
			dispatch({
				type: "@webItems/update",
				payload: { ...item, amount: amount.amount },
			});
		} else dispatch({ type: "@webItems/remove", payload: { id: item.id } });
	}, [amount.amount]);
	return (
		<div className={classes["radio-generic-container"]}>
			<div className={classes["radio-generic-help-group"]}>
				<label>{name}</label>
				<div className={classes["radio-generic-help"]} onClick={handleOpen}></div>
			</div>
			<span></span>
			<NumberInput
				reset={amount.reset}
				increase={amount.increase}
				decrement={amount.decrement}
				amount={amount.amount}
			/>
			<CustomModal open={open} handleClose={handleClose} modalInfo={{ title: name, description: item.description }} />

		</div>
	);
};

export default RadioYesNo;
