import { useState } from "react";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import { CardActionArea } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { DOMAIN } from "../../APIClient/endPoints";
import CardWrapper from "../ui/wrappers/CardWrapper";
// import IMGWEB from "../../assests/images/informative-web.png";

import classes from "./Card.module.css";

export default function Card({ element, active, onSelectWebType }) {
  const { t } = useTranslation()
  const [show, setShow] = useState(false);
  const { name, description, image } = element;
  const selectWebTypeHandler = () => {
    if (onSelectWebType) {
      onSelectWebType(element);
    }
  };

  return (
    <CardWrapper customStyle={{ borderRadius: "32px" }}>
      <div
        className={
          active
            ? `${classes["card-container"]} ${classes["active"]}`
            : classes["card-container"]
        }
        onClick={selectWebTypeHandler}
      >
        <div className={classes["card-img"]}>
          <img src={`${DOMAIN}${image}`} alt="alt-web" />
        </div>
        <div className={classes["card-information"]}>
          <div className={active ? `${classes["card-active"]} ${classes["card-title"]}` : `${classes["card-deactive"]} ${classes["card-title"]}`}>{name}</div>
          <p className={classes["card-text"]}>
            <span>{description.slice(0, 210)}</span>
            {show && active ? (
              <span>
                {description.slice(210, -1)}
                <div onClick={() => setShow(!show)} className={active ? classes["card-active"] : classes["card-deactive"]} ><u>{t("WEB.SEE-LESS")}</u></div>
              </span>
            ) : (
              <div onClick={() => setShow(!show)} className={active ? classes["card-active"] : classes["card-deactive"]}><u>{t("WEB.SEE-MORE")}</u></div>
            )}
          </p>
        </div>
      </div>
    </CardWrapper>
  );
}
