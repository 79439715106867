import useStorage from './useStorage'
import DigitalMarketing from "../components/Marketing/DigitalMarketing"
import WebPage from "../components/WebPage/WebPage/WebPage";
import InformativeWeb from '../components/WebPage/TypeWeb/InformativeWeb';
// import Complements from '../components/WebPage/Complements/Complements';
import AdminWebGroupItems from '../components/AdminWeb/AdminWebGroupItems/AdminWebGroupItems'

export const useElement = () => {
    const { activeServices, webServices } = useStorage()
    let pages = []
    activeServices.forEach(service => {
        if (service.name === 'Marketing Digital'){
            pages.push({element: <DigitalMarketing />, name: service.name, description: service.description})
        }else if (service.name === 'Desarrollo Web' || service.name === 'Web Development'){
            pages.push({element: <WebPage />, name: service.name, description: service.description})
            pages.push({element: <InformativeWeb />, name: service.name, description: service.description})   
            // pages.push({element: <Complements />, name: service.name, description: service.description})         
        }else if (service.name === 'Administración Web' || service.name === 'Web Administration'){
            pages.push({element: <AdminWebGroupItems items={service.items}/>, name: service.name, description: service.description})
        }
    })
    return{
        activeServices,
        webServices,
        pages,
    }
}