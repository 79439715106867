import classes from './Policy.module.css'

const Policy = () => {
    return <section className={classes["wrapper__data-polices"]}>
        <h1>Tratamiento de Datos Personales</h1>
        <div>
            <p>El tratamiento de los datos personales se hace en cumplimiento de la ley estatutaria de protección de
                datos (ley 1581 de 2012) mas específicamente los art.17 literal K y 18 literal F, los art.15 y 20 de la
                constitución política de Colombia y el el art. 13 de la ley 1377 de 2013 por la cuela se reglamenta la
                ley anterior, el presente manual para la política y tratamiento de datos personales será aplicable a la
                totalidad de datos recogidos por Wikpis Marketing Digital</p>
            <span>1. Política de Protección de Datos</span>
            <p>
                Uso de datos personales de los clientes registrados en el sitio web. Al momento de la inscripción del
                cliente en el sitio, estará aceptando expresamente el acceso, archivo y uso de la información personal y
                datos sensibles que allí deposite, así mismo se solicitará su consentimiento expreso, para enviarle
                información promocional o comercial sobre los productos y/o la marca. El uso que se le dará a esta
                información, será exclusivamente para validar las órdenes de compra, promover los productos que se
                encuentran en el sitio y analizar el perfil del usuario que ingresa al sitio, adicionalmente, podrá
                enviar información promocional o comercial de WIKPIS MARKETING DIGITAL en caso de que el cliente así lo
                hubiera aceptado expresamente. WIKPIS MARKETING DIGITAL garantiza la protección de esta información.
                Al momento de la aceptación del cliente de estos términos y condiciones, está manifestando de forma
                expresa, libre y previa que conoce los derechos que las leyes le conceden como titular de su información
                personal, y que a continuación mencionamos:
            </p>
            <ol>
                <li>a) Conocer, actualizar y rectificar sus datos personales frente a WIKPIS MARKETING DIGITAL o
                    cualquier responsable o encargado por este, para su tratamiento. Este derecho podrá ejercerlo el
                    cliente sobre datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o
                    aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado.</li>
                <li>b) Ser informado por el responsable o el encargado de tratar la información, previa
                    solicitud, respecto al uso que le ha dado a los datos personales.</li>
                <li>c) En caso de que el cliente no desee recibir dicha información, podrá manifestarlo en los
                    correos corporativos de Wikpis Marketing Digital info@wikpis.com </li>
                <li>d) De no ser respetados los principios, derechos y garantías constitucionales y legales
                    podrá revocar la autorización otorgada para su uso. Dicha revocatoria y/o supresión, será
                    procedente sólo cuando la Superintendencia de Industria y Comercio haya determinado que, en
                    alguna parte del proceso de tratamiento de la información, el responsable o encargado han
                    incurrido en conductas contrarias a la Ley y a la Constitución.</li>
                <li>e) Acceder a los datos personales que haya proporcionado para tratamiento, de forma
                    gratuita. El cliente manifiesta que tiene autonomía y otorga la autorización, para
                    suministrar información sobre datos sensibles, no sensibles y personales, que sean
                    solicitados por WIKPIS MARKETING DIGITAL. Con respecto a lo anterior, si el cliente no
                    proporciona la información personal que el sitio requiera, estará interfiriendo en el
                    correcto funcionamiento del mismo, así como en la prestación de sus servicios y contenidos.
                </li>
            </ol>
            <p>
                Si requiere información adicional sobre estos temas, puede escribir a los correos corporativos de
                Wikpis, info@wikpis.com.
                WIKPIS MARKETING DIGITAL no se hace responsable por interceptaciones ilegales o violación de sus
                sistemas o bases de datos por parte de personas inescrupulosas y no autorizadas, ni por la indebida
                utilización de la información obtenida por esos medios.
            </p>
            <span>0. Modificaciones del Sitio</span>
            <p>WIKPIS MARKETING DIGITAL, se reserva el derecho de modificar sin previo aviso y en forma unilateral los
                Términos y Condiciones de uso, definidos para el sitio web de la compañía, en todo o en parte. Los
                términos que lleguen a modificarse se publicarán en el sitio web.
                Así mismo, podrán modificarse el diseño, su presentación, configuración, requisitos de registro y
                utilización del sitio, sin que esto genere un derecho a reclamo o indemnización a favor del cliente.
                El cliente será responsable de revisar los Términos y Condiciones cada vez que haga uso del sitio. El
                uso frecuente del sitio que haga el cliente cuando se hayan realizado cambios en estos Términos y
                Condiciones de uso, dará a entender que acepta y está de acuerdo con dichos cambios.
            </p>
            <span>0. Propiedad Intelectual y Derechos de Autor</span>
            <p>
                El sitio web <a href="http://wikpis.com" target="_blank" rel="noopener noreferrer">www.wikpis.com</a>
                está protegido por derechos de autor, y la información allí contenida es propiedad de WIKPIS MARKETING
                DIGITAL, en consecuencia está prohibido el uso no autorizado de cualquier material que allí se encuentre
                (fotos, videos, logotipos, textos, códigos, entre otros), lo que puede representar una infracción a las
                leyes de Derechos de Autor, y conllevar a sanciones civiles, comerciales y/o penales. No está permitida
                la modificación, reproducción y/o distribución del contenido del sitio, así como tampoco usarlo con
                fines comerciales o públicos. En caso de descargas o copias de cualquier material del sitio web con
                fines personales o no comerciales, se deberán mantener los avisos de Derechos de Autor y marca
                registrada que se encuentren en el material. En este Sitio Web está prohibido:
            </p>
            <ul>
                <li>Alojar págin cualquier sitio externo dentro del sitio web de la empresa.</li>
                <li>Hacer uso de dispositivos, programas, métodos o algoritmos para acceder, copiar o monitorear
                    cualquiera de los contenidos del sitio</li>
                <li>Obtener acceso a cualquier parte del sitio que esté restringida, para el uso de los clientes, sus
                    servidores o redes a través del “haking” o cualquier otro método.</li>
                <li>Intentar alterar el funcionamiento de los servidores que alojan el sitio o buscar los puntos
                    vulnerables con respecto a la seguridad, que puedan derivar en la alteración de su funcionamiento.
                </li>
                <li>Intentar interferir el normal funcionamiento del sitio y/o sus transacciones, por medio de algún
                    dispositivo o software.</li>
                <li>Hacer intentos para manipular la información que se emita desde o hacia el sitio, con el fin de
                    suplantar la identidad de Wikpis Marketing Digital o la de cualquier otra persona.</li>
                <li>Usar inadecuadamente el sitio para cometer actos ilícitos o que afecten los derechos o intereses de
                    terceros, o que vayan en contra de los Términos y Condiciones de uso que se señalan en este sitio,
                    así como hechos que alteren el orden público, la moral y buenas costumbres</li>
                <li>En caso de detectar cualquier anomalía o uso inadecuado en www.wikpis.com, WIKPIS MARKETING DIGITAL
                    tiene la potestad de tomar acciones legales. </li>
            </ul>
            <span>Limitación de Responsabilidad</span>
            <p>
                Al hacer uso de este sitio, el cliente acepta y reconoce que WIKPIS MARKETING DIGITAL, no es responsable
                por los riesgos a los que pueda estar expuesta su plataforma tecnológica e informática. Esta
                responsabilidad también lo exonera irrevocablemente de la responsabilidad proveniente de los riesgos
                tecnológicos o de errores en los aplicativos, virus, gusanos, huecos, bases de datos, sabotajes y otros
                delitos informáticos.
                WIKPIS MARKETING DIGITAL puede realizar en el momento que considere, sin previo aviso, y sin que esto
                conlleve a un derecho de compensación, actividades de mantenimiento, cambios o correcciones, entre los
                que se contemplan:
            </p>
            <ul>
            <li>Hacer cambios o modificaciones en todo o en parte al sitio.</li>
            <li>Interferir o interrumpir el funcionamiento del sitio, de forma programada o no.</li>
            <li>Bloquear, suspender, alterar y/o finalizar la operación o el acceso a todo o parte del sitio.</li>
            </ul>
        </div>

    </section>
}

export default Policy;